import React, { Fragment } from "react";
import { CircularProgressStyled, CircularProgressWhite } from "./styles";

const height = 24;
const width = 24;

const SharedSpinner = (props) => {
  const renderSpinner = () => {
    if (props.type == "white") {
      return (
        <CircularProgressWhite
          width={props.width || width}
          height={props.height || height}
        />
      );
    }

    return (
      <CircularProgressStyled
        width={props.width || width}
        height={props.height || height}
      />
    );
  };

  return <Fragment>{renderSpinner()}</Fragment>;
};

export default SharedSpinner;
