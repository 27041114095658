import EyeIcon from "@heroicons/react/24/outline/EyeIcon";
import EyeSlashIcon from "@heroicons/react/24/outline/EyeSlashIcon";
import Check from "@mui/icons-material/Check";
import ErrorOutline from "@mui/icons-material/ErrorOutline";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import InfoIcon from "@mui/icons-material/Info";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { styled as styling } from "@mui/material/styles";
import { withTheme } from "@mui/styles";
import styled, { css } from "styled-components";
import Color from "../../../assets/colors";
import { FontSize } from "../../../assets/font";

// Button Component //
export const SignUpButton = withTheme(styled(Button)`
  && {
    font-weight: 700;
    height: 42px;
    text-transform: capitalize;
    font-size: 1rem;
    width: 100%;
  }
`);

// TextField Components //
export const TextInput = styled(TextField)`
  && {
    display: flex;
    max-width: 100%;

    & .MuiInputBase-root {
      height: 50px;
      padding: 0 1px;
      transform: translate(0, 2px);
    }

    & .MuiInputBase-input {
      padding-left: 1.125rem;
    }

    & .MuiFilledInput-input:-webkit-autofill {
      height: 12px;
    }

    & .MuiFilledInput-underline:before {
      display: none;
    }

    & .MuiFilledInput-underline:after {
      display: none;
    }

    &
      .MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel) {
      margin-top: 0;
    }

    ${(props) =>
      (props.type == "email" ||
        props.type == "password" ||
        props.password_shown == "true") &&
      css`
        & .MuiInputLabel-root {
          transform: translate(20px, 15px) scale(1);
        }

        & .MuiInputLabel-filled.MuiInputLabel-shrink {
          transform: translate(20px, 10px) scale(0.75);
        }
      `}
  }
`;

// Icon Components //
export const PasswordIcon = styled(HelpOutlineIcon)`
  && {
    width: 20px;
    height: 20px;
    margin-left: 4px;
  }
`;

export const VisibilityIconStyled = styled(EyeIcon)`
  && {
    cursor: pointer;
    width: 1.25rem;
    height: 1.25rem;
  }
`;

export const VisibilityOffIconStyled = styled(EyeSlashIcon)`
  && {
    cursor: pointer;
    width: 1.25rem;
    height: 1.25rem;
  }
`;

export const InfoIconStyled = withTheme(styled(InfoIcon)`
  && {
    cursor: pointer;
    width: 14px;
    margin-left: 4px;
  }
`);

// Typography Components //
export const LabelStyled = withTheme(styled(Typography)`
  && {
    text-align: left;
    font-weight: ${(props) => (props.caption == "true" ? "normal" : "700")};
    font-size: ${(props) => (props.caption == "true" ? "0.875rem" : "1.5rem")};

    ${(props) =>
      props.divider_text == "true" && {
        color: `${Color.secondaryGrey}`,
        margin: "0 10px",
      }};

    ${(props) =>
      props.disclaimer == "true" && {
        fontWeight: "400",
        fontSize: "0.75rem",
        textAlign: "center",
        whiteSpace: "normal",
        letterSpacing: "0.15px",
      }};

    ${(props) =>
      props.checkbox == "true" && {
        fontWeight: "400",
        fontSize: "0.75rem",
        fontStyle: "italic",
        textAlign: "start",
        whiteSpace: "normal",
        letterSpacing: "0.15px",
        lineHeight: "20px",
      }};

    ${(props) => props.theme.breakpoints.down("mobileL")} {
      ${(props) =>
        props.log_in_button == "true" &&
        css`
          margin: 0;
        `}
    }
  }
`);

export const TextStyled = styling(Typography)({});

export const BoxContainer = styling(Box)({
  display: "flex",
});

export const DisclaimerSpan = styling("span")({
  fontWeight: 700,
});

export const LabelDivider = withTheme(styled(Typography)`
  && {
    font-size: 0.875rem;
    color: ${Color.secondaryGrey};
    margin: 0 12px;
    text-align: center;

    ${(props) => props.theme.breakpoints.down("tablet")} {
      font-size: 0.75rem;
      margin: 0 0.25rem;
    }
  }
`);

export const ActionLabelStyled = withTheme(styled(Typography)`
  && {
    font-size: 0.875rem;
    font-weight: bold;
    color: ${Color.hiredlyPurple};
    white-space: nowrap;
    cursor: pointer;
    text-transform: capitalize;
  }
`);

export const PasswordInfoText = withTheme(styled(Typography)`
  && {
    font-size: 0.875rem;
    cursor: default;
  }
`);

export const PasswordStrengthText = withTheme(styled.span`
  && {
    font-size: 0.875rem;
    cursor: default;
    color: ${(props) =>
      props.password_strength == "medium"
        ? "#FCA80B"
        : props.password_strength == "strong"
        ? "#22AD5C"
        : "#FF4758"};
    font-weight: bold;
  }
`);

export const ReferralLabel = withTheme(styled(Typography)`
  && {
    font-size: ${FontSize.desktopCaption};
    text-align: center;
    max-width: 410px;
    font-weight: ${(props) => (props.$bold === "true" ? "700" : null)};
    margin: ${(props) =>
      props.$margin_bottom === "true" ? "0 0 0.5rem 0" : "0"};

    span {
      font-weight: ${FontSize.bold};
    }

    ${(props) =>
      props.subtitle == "true" &&
      css`
        margin-top: 5px;
        font-style: ${FontSize.italic};
      `}
  }
`);

// Dialog Components //
export const DialogContentStyled = withTheme(styled(DialogContent)`
  padding-inline: 32px !important;
  padding-block: 24px 32px !important;
`);

export const FormTitle = styling(Typography)({
  fontSize: "1.563rem",
  fontWeight: 700,
});

// Box Component //
export const LinkedInButton = styled(Box)`
  && {
    width: 3rem;
    height: 3rem;
    background-color: ${Color.linkedInBlue};
    border-radius: 50%;
    cursor: pointer;
    display: grid;
    justify-items: center;
    align-items: center;
    box-shadow: 2px 2px 0 ${Color.black};
  }
`;

export const GoogleButton = withTheme(styled(Box)`
  && {
    width: 3rem;
    height: 3rem;
    background-color: ${Color.white};
    border-radius: 50%;
    cursor: pointer;
    justify-items: center;
    align-items: center;
    border: 1px solid ${Color.black};
    box-shadow: 2px 2px 0 ${Color.black};
    display: ${(props) => (props.$hide_button === "true" ? "none" : "grid")};
  }
`);

// Grid Components //
export const EmailContainer = styled(Grid)`
  && {
    width: 100%;
    border-radius: 4px;
    background: red;
  }
`;

export const EmptyContainer = styled(Grid)`
  && {
    //
  }
`;

export const GridPassword = styled(Grid)`
  && {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
`;

export const PasswordContainer = styled(Grid)``;

export const DisclaimerContainer = styled(Grid)`
  && {
    margin-top: 20px;
  }
`;

export const PasswordStrengthBar = styled(Grid)`
  && {
    height: 6px;
    background-color: ${Color.lightGrey};
    width: 100%;
    margin: 13px 0;
  }
`;

export const PasswordStrength = styled(Grid)`
  && {
    height: 6px;
    background-color: ${(props) =>
      props.password_strength == ""
        ? "none"
        : props.password_strength == "medium"
        ? "#FCA80B"
        : props.password_strength == "strong"
        ? "#22AD5C"
        : "#FF4758"};
    max-width: ${(props) =>
      props.password_strength == "medium"
        ? "50%"
        : props.password_strength == "strong"
        ? "100%"
        : "30%"};
  }
`;

export const PasswordStrengthInfo = styled(Grid)`
  && {
    display: flex;
    justify-content: center;
  }
`;

export const SSOSignUpContainer = withTheme(styled(Grid)`
  && {
    display: flex;
    justify-content: center;
    gap: 1.5rem;

    ${(props) => props.theme.breakpoints.down("tablet")} {
      margin: 1rem 0;
    }
  }
`);

export const CustomDividerContainer = styled(Grid)`
  && {
    display: flex;
    margin: 20px 0;
    align-items: center;
  }
`;

export const CustomDivider = styled.div`
  && {
    flex: 1%;
    border-bottom: 1px solid ${Color.secondaryGrey};
  }
`;

export const EmailSignUpFormContainer = styled(Grid)`
  && {
    margin: 20px 0;
  }
`;

export const EmailSignUpFormContent = styling(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "14px",
});

export const SocialSpinnerContainer = styled.div`
  && {
    height: 170px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const ButtonContainer = withTheme(styled(Grid)`
  && {
    text-align: center;
    display: flex;
    margin: 1rem 0;
    gap: 0.25rem;
    align-items: baseline;
    flex-wrap: wrap;
  }
`);

export const ReferralContainer = withTheme(styled(Grid)`
  && {
    border-radius: 0.5rem;
    padding: 5px;
    margin-bottom: 10px;
    background-color: ${Color.lightGrey};
  }
`);

// Progress Components //
export const CircularProgressStyled = styled(CircularProgress)`
  && {
    &.MuiCircularProgress-root {
      //
    }
  }
`;

// Anchor Component //
export const StyledLink = styled.a`
  && {
    color: ${Color.hiredlyPurple};
    text-decoration: none;
    font-weight: 700;
    display: inline;

    ${(props) =>
      props.checkbox == "true" && {
        fontWeight: "400",
        textDecoration: "underline",
      }};

    &:hover {
      text-decoration: underline;
    }
  }
`;

// Span Component //
export const ErrorIconStyled = styling(ErrorOutline)({
  color: Color.error,
});

export const TypographyStyled = styling(Typography)({
  //
});

export const CheckboxStyled = styling(Checkbox)({
  padding: "0rem",
  "& .Mui-checked": {
    color: Color.black,
  },

  "&:hover": {
    background: "transparent",
  },
});

export const UncheckedIcon = styling(Box)((props) => ({
  width: "18px",
  height: "18px",
  borderRadius: "0.25rem",
  border: props.onError
    ? `1.5px solid ${Color.error}`
    : `1px solid ${Color.black}`,
}));

export const CheckedIcon = styling(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  width: "18px",
  height: "18px",
  borderRadius: "0.25rem",
  border: `1px solid ${Color.black}`,
  backgroundColor: Color.black,
});

export const CheckStyled = styling(Check)({
  width: "12px",
  height: "12px",
  color: Color.white,
});
