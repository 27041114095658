import React, { Fragment, useState } from "react";
import {
  TextInput,
  LabelStyled,
  ForgotPasswordContainer,
  DialogContentStyled,
  ForgotPasswordFormContainer,
  ForgotPasswordButtonContainer,
  ResetPasswordButton,
} from "./styles";
import { FontSize } from "../../../assets/font";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { forgotPassword } from "../../../redux/actions/user_action";
import {
  hideSnackBar,
  showSnackBar,
} from "../../../redux/actions/snackbar_action";
import * as types from "../../../redux/types/user_type";
import { triggerSnackBar } from "../../../helpers/snackbar_management";

function TextInputFunction(props) {
  const { label, handleChange, type, email, onKeyDown, forgettingPassword } =
    props;

  return (
    <Fragment>
      <TextInput
        required
        hiddenLabel
        disabled={forgettingPassword}
        value={email}
        type={`${type}`}
        onChange={handleChange}
        id={`filled-required-${label}`}
        variant="filled"
        placeholder={"Email*"}
        InputProps={{
          disableUnderline: true,
          style: {
            //
          },
        }}
        inputProps={{
          style: {
            transform: "translate(0px, 1px)",
            fontSize: `${FontSize.desktopCaption}`,
            borderRadius: "20px",
            height: "5px",
            paddingLeft: "20px",
          },
        }}
        onKeyDown={onKeyDown}
      />
    </Fragment>
  );
}

function ForgotPassword(props) {
  const [email, setEmail] = useState("");
  const { forgettingPassword } = props;

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmitForgetPassword = (event) => {
    /* Prevents page from reloading after form submission */
    if (event) {
      event.preventDefault();
    }

    if (email == "") {
      const cParams = {
        message: "Please key in email to reset password",
        severity: "error",
      };
      triggerSnackBar(props.hideSnackBar, props.showSnackBar, cParams);

      return;
    }

    let params = {
      email: email,
      canCancel: false,
    };

    props
      .forgotPassword(params)
      .then((response) => {
        if (response.type === types.FORGET_PASSWORD_SUCCEED) {
          let cParams = {
            message: `We've sent an email to ${params.email} with instructions on how to reset your password.`,
            severity: "success",
          };
          triggerSnackBar(props.hideSnackBar, props.showSnackBar, cParams);
          props.handleClose();
          return;
        } else {
          let cParams = {
            message:
              "Failed to forget password at the moment, please try again later.",
            severity: "error",
          };
          triggerSnackBar(props.hideSnackBar, props.showSnackBar, cParams);
        }
      })
      .catch((error) => {
        return;
      });
  };

  const onKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSubmitForgetPassword(event);
    }
  };

  return (
    <Fragment>
      <DialogContentStyled>
        <ForgotPasswordFormContainer>
          <form autoComplete="off">
            <ForgotPasswordContainer container justifyContent="center">
              <ForgotPasswordContainer item>
                <LabelStyled $margin>forgot your password?</LabelStyled>
                <TextInputFunction
                  forgettingPassword={forgettingPassword}
                  label={"email"}
                  type="text"
                  email={email}
                  handleChange={handleEmailChange}
                  onKeyDown={onKeyDown}
                />
              </ForgotPasswordContainer>
            </ForgotPasswordContainer>
          </form>
        </ForgotPasswordFormContainer>

        <ForgotPasswordButtonContainer>
          <ResetPasswordButton
            disabled={forgettingPassword}
            variant="contained"
            onClick={handleSubmitForgetPassword}
          >
            reset password
          </ResetPasswordButton>
        </ForgotPasswordButtonContainer>
      </DialogContentStyled>
    </Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    forgettingPassword: state.user.forgettingPassword,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    forgotPassword: bindActionCreators(forgotPassword, dispatch),
    hideSnackBar: bindActionCreators(hideSnackBar, dispatch),
    showSnackBar: bindActionCreators(showSnackBar, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
