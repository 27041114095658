import Color from "../../../assets/colors";
import styled from "styled-components";
import withTheme from "@mui/styles/withTheme";
import Grid from "@mui/material/Grid";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";

// Icon Component //
export const SearchIconStyled = styled(SearchIcon)`
  && {
    height: 0.8em;
    width: 0.8em;
    font-size: 1.45rem;
  }
`;

// Input Component //
export const InputBaseStyled = withTheme(styled(InputBase)`
  && {
    margin-top: 0px;
    width: 74%;

    & .MuiInputBase-input {
      padding: 8px 0;
    }
  }
`);

// Paper Component //
export const NavbarSearchStyled = withTheme(styled(Paper)`
  && {
    cursor: pointer;
    border-radius: 20px;
    padding-left: 25px;
    border: 1px solid ${Color.black};
    display: flex;
    justify-content: space-between;

    ${(props) => props.theme.breakpoints.between("tablet", "lg")} {
      width: 350px;
    }
  }
`);

// Dialog Components //
export const DialogContentStyled = styled(DialogContent)`
  && {
    padding: 0 12px;
  }
`;
export const DialogActionsStyled = styled(DialogActions)`
  && {
    justify-content: center;
  }
`;
export const DialogTitleStyled = styled(DialogTitle)`
  && {
    font-weight: 800;
    text-transform: capitalize;
  }
`;
export const DialogStyled = withTheme(styled(Dialog)`
  && {
  }
`);

export const IconButtonStyled = styled(IconButton)`
  && {
    margin-left: ${(props) => (props.$close_button ? "0" : "12px")};
    margin-right: ${(props) => (props.$close_button ? "20px" : null)};
  }
`;
export const IconButtonSearchBar = styled(IconButton)`
  && {
    background-color: ${Color.hiredlyPurple};
    color: ${Color.white};
    margin: 4px;

    &:hover {
      background-color: ${Color.black};
    }
  }
`;

// Grid Components //
export const GridFilterContent = styled(Grid)`
  && {
    display: flex;
    justify-content: ${(props) =>
      props.$space_between ? "space-between" : "center"};
    align-items: center;
    padding: 0;
  }
`;
export const GridStyled = withTheme(styled(Grid)`
  && {
    ${(props) => props.theme.breakpoints.up("mobileL")} {
      display: none;
    }
  }
`);
