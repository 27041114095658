import { store } from "../redux/stores/store";
import * as types from "../redux/types/company_type";

const clearAndFetchCompanies = (updateJobId, clearJobList, getJobs) => {};

const formCompanyQueryParam = (companyFilter, refresh) => {
  let currentLoadingCompanyKey = new Date().getTime();

  // On initial page load indicate no filter is applied
  let hasFilter = false;

  store.getState().companies.currentLoadingCompanyKey =
    currentLoadingCompanyKey;

  let params = {
    refresh: refresh,
    after: refresh ? "" : store.getState().companies.companyEndCursor ?? "",
    currentLoadingCompanyKey: currentLoadingCompanyKey,
    cancelTokenKey: types.FETCHING_COMPANY_KEY,
    canCancel: false,
  };

  if (companyFilter["keyword"] && companyFilter["keyword"] != "") {
    hasFilter = true;
    params["keyword"] = companyFilter["keyword"];
  }

  if (companyFilter["industry_ids"]?.length > 0) {
    hasFilter = true;
    params["industryIds"] = companyFilter["industry_ids"];
  }

  if (companyFilter["state_regions"]?.length > 0) {
    hasFilter = true;
    params["stateRegions"] = companyFilter["state_regions"];
  }

  if (companyFilter["company_sizes"]?.length > 0) {
    hasFilter = true;
    params["companySizes"] = companyFilter["company_sizes"];
  }

  // Overwrite referral token search param if other filters are applied after
  if (!hasFilter && companyFilter["referral_token"]?.length > 0) {
    params["referralToken"] = companyFilter["referral_token"];
  }

  // Clear referral token search param when other filters are applied
  if (hasFilter) {
    store.getState().companies.companyFilter["referral_token"] = "";
  }

  return params;
};

const formCompanySuggestionQueryParam = (companyFilter, refresh) => {
  let currentSuggestionKey = new Date().getTime();
  store.getState().companies.currentSuggestionKey = currentSuggestionKey;

  let params = {
    refresh: refresh,
    page: refresh
      ? 1
      : store.getState().companies.suggestionPage
      ? store.getState().companies.suggestionPage
      : 1,
    canCancel: false,
    currentSuggestionKey: currentSuggestionKey,
    cancelTokenKey: types.FETCH_COMPANY_SUGGESTION_KEY,
  };

  if (companyFilter["keyword"] && companyFilter["keyword"] != "") {
    params["keyword"] = companyFilter["keyword"];
  }

  if (
    companyFilter["type"] == "industry" &&
    companyFilter["industry_ids"]?.length > 0
  ) {
    params["industryIds"] = companyFilter["industry_ids"];
  }

  if (
    companyFilter["type"] == "location" &&
    companyFilter["state_regions"]?.length > 0
  ) {
    params["stateRegions"] = companyFilter["state_regions"];
  }

  if (companyFilter["company_sizes"]?.length > 0) {
    params["companySizes"] = companyFilter["company_sizes"];
  }

  return params;
};

export {
  clearAndFetchCompanies,
  formCompanyQueryParam,
  formCompanySuggestionQueryParam,
};
