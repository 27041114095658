import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Menu, { MenuProps } from "@mui/material/Menu";
import Typography, { TypographyProps } from "@mui/material/Typography";
import { styled as styling } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import withTheme from "@mui/styles/withTheme";
import styled from "styled-components";
import Color from "../../../assets/colors";

// Menu Component //
interface MenuStyledProps extends MenuProps {
  hasMaxWidth?: boolean;
}

export const MenuStyled = styling(Menu)<MenuStyledProps>((props) => ({
  ul: {
    paddingTop: "0",
  },

  "& .MuiPaper-root": {
    width: props?.hasMaxWidth ? "auto" : "400px",
    borderRadius: "1.5rem",
    height: "14rem",
    overflowY: "hidden",
    marginTop: "0.75rem",

    [props.theme.breakpoints.down(768)]: {
      maxWidth: props?.hasMaxWidth ? "260px" : "auto",
    },
  },
}));

interface ButtonStyledProps extends ButtonProps {
  open?: boolean;
  roundedRectangle?: boolean;
}
// Button Component //
export const ButtonStyled = styling(Button)<ButtonStyledProps>((props) => ({
  marginBottom: "1rem",
  borderRadius: props?.roundedRectangle ? "8px" : "4.5rem",
  height: "3rem",
  width: "100%",
  border: props?.open
    ? `2px solid ${Color.hiredlyPurple}`
    : `2px solid ${Color.secondaryGrey}`,
  backgroundColor: Color.white,
  display: "flex",
  alignItems: "center",
  paddingLeft: "1.5rem",
  paddingRight: "0.5rem",
  paddingInline: props?.roundedRectangle ? "16px 8px" : "1.5rem 0.5rem",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",

  "&:hover": {
    border: `2px solid ${Color.hiredlyPurple}`,
    backgroundColor: Color.white,
  },
}));

// Scrollbar Component //
export const SubSpecialisationScrollBar = makeStyles((theme) => ({
  scrollBar: {
    "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
      backgroundColor: "transparent",
      width: 3,
      overflow: "auto",
    },
    "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
      borderRadius: 8,
      backgroundColor: Color.hiredlyPurple,
      overflow: "auto",
    },
    "&::-webkit-scrollbar-track": {
      marginTop: "0.5rem",
      marginBottom: "0.5rem",
      height: "3.25rem",
    },
  },
}));

export const MajorSpecialisationScrollBar = makeStyles((theme) => ({
  scrollBar: {
    "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
      backgroundColor: "transparent",
      width: 3,
      overflow: "auto",
    },
    "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
      borderRadius: 8,
      backgroundColor: Color.hiredlyPurple,
      overflow: "auto",
    },
    "&::-webkit-scrollbar-track": {
      marginTop: "1rem",
      marginBottom: "1rem",
      height: "3.25rem",
    },
  },
}));

// Icon Button Component //
export const IconButtonStyled = styling(IconButton)({
  width: "1.75rem",
  height: "1.75rem",
});

// Box Component //
export const MajorSpecialisationItem = styling(Box)({
  padding: "0.75rem 1rem",
  display: "grid",
  gridTemplateColumns: "1fr auto",
  cursor: "pointer",

  ":hover": {
    backgroundColor: Color.lightHiredlyPurple,
  },
});

export const SubSpecialisationItem = styling(Box)({
  padding: "0.5rem 1rem 0.5rem 3.25rem",
  cursor: "pointer",

  ":hover": {
    backgroundColor: Color.lightHiredlyPurple,
  },
});

export const BoxStyled = styling(Box)({
  position: "relative",
});

export const ErrorTextBox = styling(Box)({
  display: "flex",
  height: "100%",
  alignItems: "center",
  justifyContent: "center",
});

export const SubSpecialisationContent = styling(Box)({
  display: "grid",
  gridTemplateColumns: "auto 1fr",
  padding: "1rem",
  columnGap: "0.75rem",
  cursor: "pointer",
  position: "sticky",
  top: "0",
  backgroundColor: Color.white,
  borderBottom: `1px solid ${Color.grey}`,
});

export const MajorSpecialisationBox = withTheme(styled(Box)`
  && {
    border-radius: 1.5rem;
    background-color: ${Color.white};
    height: 14rem;
    width: 100%;
    box-shadow: 0 0.25rem 1rem 0 rgba(0, 0, 0, 0.25);
    overflow-y: scroll;
    border: 1px solid ${Color.secondaryGrey};
  }
`);

export const SubSpecialisationBox = withTheme(styled(Box)`
  && {
    border-radius: 1.5rem;
    background-color: ${Color.white};
    height: 14rem;
    width: 100%;
    box-shadow: 0 0.25rem 1rem 0 rgba(0, 0, 0, 0.25);
    overflow-y: scroll;
    border: 1px solid ${Color.secondaryGrey};
  }
`);

export const DropdownBox = styling(Box)({
  position: "relative",
  marginBottom: "1rem",
  borderRadius: "4.5rem",
  height: "3rem",
  width: "auto",
  border: `2px solid ${Color.secondaryGrey}`,
  backgroundColor: Color.white,
  display: "grid",
  gridTemplateColumns: "1fr auto",
  alignItems: "center",
  paddingLeft: "1.5rem",
  paddingRight: "0.5rem",
  cursor: "pointer",

  ":hover": {
    border: `2px solid ${Color.hiredlyPurple}`,
  },
});

// Typography Component //
export const ErrorSpecialisationText = styling(Typography)({
  fontSize: "1rem",
  textAlign: "center",
});

export const MajorSpecialisationTitle = styling(Typography)({
  fontSize: "1rem",
  fontWeight: "700",
});

interface TitleStyledProps extends TypographyProps {
  smallMarginBottom?: boolean;
}
export const TitleStyled = styling(Typography)<TitleStyledProps>((props) => ({
  fontSize: "0.875rem",
  fontWeight: "700",
  marginBottom: props?.smallMarginBottom ? "8px" : "13px",
  textTransform: "capitalize",
}));

export const SelectedSpecialisationText = withTheme(styled(Typography)`
  && {
    margin-right: auto;
    max-width: 250px;
    flex: 1;
    font-size: 1rem;
    color: ${(props) =>
      props.$value === "true" ? Color.black : Color.secondaryGrey};
    text-align: start;
    text-transform: capitalize;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    ${(props) => props.theme.breakpoints.down("tablet")} {
      font-size: 0.875rem;
    }
  }
`);

export const MajorCategoryItemText = styling(Typography)((props) => ({
  fontSize: "1rem",
  fontWeight: "400",
  letterSpacing: "0.15px",

  [props.theme.breakpoints.down(768)]: {
    fontSize: "0.875rem",
  },
}));

// Icon Component //
export const KeyboardArrowDownIconStyled = styling(KeyboardArrowDownIcon)({
  //
});

export const KeyboardArrowUpIconStyled = styling(KeyboardArrowUpIcon)({
  //
});

export const ArrowDropDownIconStyled = styling(ArrowDropDownIcon)({});

export const ArrowDropUpIconStyled = styling(ArrowDropUpIcon)({});

export const ArrowLeftIconStyled = styling(ChevronLeftIcon)({
  //
});

export const ArrowRightIconStyled = styling(ChevronRightIcon)({
  //
});
