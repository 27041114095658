import { MouseEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSpecialisation } from "../../../redux/actions/job_action";
import {
  openSpecialisationDropdown,
  openSubSpecialisationDropdown,
  updateSubSpecialisation,
} from "../../../redux/actions/user_action";
import { SubSpecialisation } from "./SubSpecialisation";
import {
  ArrowDropDownIconStyled,
  ArrowDropUpIconStyled,
  ArrowRightIconStyled,
  BoxStyled,
  ButtonStyled,
  ErrorSpecialisationText,
  ErrorTextBox,
  IconButtonStyled,
  KeyboardArrowDownIconStyled,
  KeyboardArrowUpIconStyled,
  MajorCategoryItemText,
  MajorSpecialisationBox,
  MajorSpecialisationItem,
  MajorSpecialisationScrollBar,
  MenuStyled,
  SelectedSpecialisationText,
  TitleStyled,
} from "./styles";

export function MajorSpecialisation(props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const classes = MajorSpecialisationScrollBar();
  const { value, specialisationHandler, roundedRectangle } = props;
  const specialisations = useSelector(
    (state: any) => state?.jobs?.specialisation
  );
  const dropdownState = useSelector(
    (state: any) =>
      state?.user?.openSpecialisationDropdown?.openSpecialisationDropdown
  );
  const subSpecialisationDropdown = useSelector(
    (state: any) =>
      state?.user?.openSubSpecialisationDropdown?.openSubSpecialisationDropdown
  );
  const dispatch: any = useDispatch();

  useEffect(() => {
    dispatch(getSpecialisation());
  }, []);

  function handleClick(event: MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
    dispatch(openSpecialisationDropdown(true));
    dispatch(openSubSpecialisationDropdown(false));
  }

  function handleClose() {
    setAnchorEl(null);
    dispatch(openSubSpecialisationDropdown(false));
  }

  function handleClickMajorSpecialisation(index: number) {
    dispatch(openSubSpecialisationDropdown(true));
    dispatch(updateSubSpecialisation(specialisations[index]));
  }

  const arrowUpIcon = roundedRectangle ? (
    <ArrowDropUpIconStyled />
  ) : (
    <KeyboardArrowUpIconStyled />
  );
  const arrowDownIcon = roundedRectangle ? (
    <ArrowDropDownIconStyled />
  ) : (
    <KeyboardArrowDownIconStyled />
  );

  return (
    <BoxStyled>
      <TitleStyled smallMarginBottom={roundedRectangle}>
        Specialisation*
      </TitleStyled>

      <ButtonStyled
        onClick={handleClick}
        open={open}
        roundedRectangle={roundedRectangle}
      >
        <SelectedSpecialisationText $value={value ? "true" : "false"}>
          {value ? value : "Please Select"}
        </SelectedSpecialisationText>
        <IconButtonStyled>
          {open ? arrowUpIcon : arrowDownIcon}
        </IconButtonStyled>
      </ButtonStyled>

      <MenuStyled
        hasMaxWidth={roundedRectangle}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        {subSpecialisationDropdown ? (
          <SubSpecialisation
            setAnchorEl={setAnchorEl}
            specialisationHandler={specialisationHandler}
          />
        ) : null}

        {dropdownState ? (
          <MajorSpecialisationBox className={classes.scrollBar}>
            {specialisations ? (
              specialisations?.map((specialisation, index) => (
                <MajorSpecialisationItem
                  key={specialisation?.id}
                  // @ts-ignore
                  onClick={() => handleClickMajorSpecialisation(index)}
                >
                  <MajorCategoryItemText>
                    {specialisation?.name}
                  </MajorCategoryItemText>
                  <ArrowRightIconStyled />
                </MajorSpecialisationItem>
              ))
            ) : (
              <ErrorTextBox>
                <ErrorSpecialisationText>
                  Unable to display specialisations
                </ErrorSpecialisationText>
              </ErrorTextBox>
            )}
          </MajorSpecialisationBox>
        ) : null}
      </MenuStyled>
    </BoxStyled>
  );
}
