import { useMediaQuery } from "@mui/material";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { EducationCardContent, WorkExperienceCardContent } from "./CardContent";
import {
  CertificateHeader,
  EducationCardHeader,
  LanguageCardHeader,
  WorkExperienceCardHeader,
} from "./CardHeader";
import {
  ButtonGroup,
  CardHeaderContainer,
  CheckIconStyled,
  IconButtonStyled,
  PencilIconStyled,
  SectionCard,
  SectionCardWrapper,
  SeeMoreButton,
  SelectButton,
} from "./styles";
import { PreviewCardProps } from "./types";
import {
  isValidCertificate,
  isValidEducation,
  isValidLanguage,
  isValidWorkExperience,
} from "./validation";

export default function PreviewCard<TData>(props: PreviewCardProps<TData>) {
  const {
    data,
    selected,
    onOpenEditMode,
    selectResumeSection,
    type,
    shouldShowError,
  } = props;
  const isTablet = useMediaQuery("(min-width:768px)");
  const CARD_CONTENT_MAX_HEIGHT = isTablet ? 200 : 180;
  const cardWrapperRef = useRef(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [showFullCard, setShowFullCard] = useState(false);
  const [showExpandButton, setShowExpandButton] = useState(false);
  const error =
    type === "certifications"
      ? !isValidCertificate(data).success
      : type === "educationsStructured"
      ? !isValidEducation(data).success
      : type === "languages"
      ? !isValidLanguage(data).success
      : !isValidWorkExperience(data).success;

  useLayoutEffect(() => {
    if (
      cardWrapperRef?.current &&
      cardWrapperRef.current.clientHeight > CARD_CONTENT_MAX_HEIGHT
    ) {
      setShowFullCard(isExpanded ? true : false);
    }
  }, [isExpanded, cardWrapperRef]);

  useEffect(() => {
    if (
      cardWrapperRef?.current &&
      cardWrapperRef.current.clientHeight > CARD_CONTENT_MAX_HEIGHT
    ) {
      setShowExpandButton(true);
    }
  }, [cardWrapperRef]);

  function handleExpandCard(e: React.MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    setShowFullCard((prev) => !prev);
    setIsExpanded((prev) => !prev);
  }

  function handleSelect(e: React.MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    selectResumeSection();
  }

  function CardHeaderComponent() {
    switch (type) {
      case "workExperiencesStructured":
        return <WorkExperienceCardHeader workExperience={data} />;
      case "educationsStructured":
        return <EducationCardHeader education={data} />;
      case "languages":
        return <LanguageCardHeader language={data} />;
      case "certifications":
        return <CertificateHeader certificate={data} />;
    }
  }

  function CardContentComponent() {
    switch (type) {
      case "workExperiencesStructured":
        return <WorkExperienceCardContent workExperience={data} />;
      case "educationsStructured":
        return <EducationCardContent education={data} />;
    }
  }

  return (
    <SectionCardWrapper
      ref={cardWrapperRef}
      selected={selected}
      noPaddingBottom={type === "languages" || type === "certifications"}
      onClick={selectResumeSection}
      error={shouldShowError && error}
    >
      <SectionCard showFullCard={showFullCard}>
        {showExpandButton && (
          <SeeMoreButton onClick={handleExpandCard}>
            {isExpanded ? "See Less" : "See More"}
          </SeeMoreButton>
        )}
        <CardHeaderContainer>
          <CardHeaderComponent />
          <ButtonGroup>
            <IconButtonStyled onClick={onOpenEditMode}>
              <PencilIconStyled />
            </IconButtonStyled>
            <SelectButton onClick={handleSelect} selected={selected} big>
              <CheckIconStyled />
            </SelectButton>
          </ButtonGroup>
        </CardHeaderContainer>

        <CardContentComponent />
      </SectionCard>
    </SectionCardWrapper>
  );
}
