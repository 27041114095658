import moment from "moment";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateResumeConfirmationDialog } from "../../redux/actions/dialog_action";
import { getUser } from "../../redux/actions/user_action";
import { isEmpty } from "lodash";

const WithOnboarding = (WrappedComponent) => {
  return (props) => {
    const Router = useRouter();
    const [allowOnboarding, setAllowOnboarding] = useState(false);

    const dispatch = useDispatch();

    const onboardingRevampDate = "2022-12-20";

    const userCompletedOnboarding = (user) => {
      const createdAt = user.created_at || user.createdAt;

      // Set user as completed onboarding if the acc is created before 1st of DEC
      if (createdAt) {
        if (moment(createdAt).isBefore(onboardingRevampDate)) {
          return true;
        }
      }

      const onboardingSuccess =
        user.onboardingComplete && user.uploadResumeAt !== null;

      return onboardingSuccess;
    };

    const user = getUser();

    useEffect(() => {
      if (user) {
        const completedOnboarding = userCompletedOnboarding(user);

        if (completedOnboarding) {
          // Done first step, no need to go through onboarding anymore
          if (Router.pathname === "/onboarding") {
            Router.replace("/");
          } else {
            setAllowOnboarding(true);
            dispatch(updateResumeConfirmationDialog(false));
          }
        } else {
          setAllowOnboarding(true);
        }
      } else {
        // Not logged in, redirect back to user landing page
        if (Router.pathname === "/onboarding") {
          Router.replace("/");
        } else {
          setAllowOnboarding(true);
        }
      }
    }, [user]);

    if (allowOnboarding) {
      return <WrappedComponent {...props} />;
    } else {
      return null;
    }
  };
};

export default WithOnboarding;
