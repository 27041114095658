import styled from "styled-components";
import Color from "../../../assets/colors";
import WorkOffIcon from "@mui/icons-material/WorkOff";
import WorkIcon from "@mui/icons-material/Work";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import withTheme from "@mui/styles/withTheme";
import { FontSize } from "../../../assets/font";
import { styled as styling } from "@mui/material/styles";
import { color } from "@mui/system";

// Div Component //
export const RecommendedTextDiv = styled.div`
  && {
    background: ${Color.hiredlyPurple};
    color: ${Color.white};
    border-radius: 0.5rem;
    width: min-content;
    padding: 3.5px 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

// Box Component //
export const DialogContentBox = withTheme(styled(Box)`
  && {
    background: ${(props) => (props.$background ? Color.lightPurple : null)};
    border-radius: 0.75rem;
    column-gap: 0.5rem;
    padding: ${(props) => (props.$padding ? "0.75rem" : null)};
    margin-bottom: ${(props) => (props.$margin_bottom ? "1rem" : null)};
    text-align: center;

    ${(props) => props.theme.breakpoints.down("sm")} {
      display: block;
      margin-bottom: 0.5rem;
    }
  }
`);

// Typography Components //
export const DialogText = withTheme(styled(Typography)`
  && {
    font-weight: ${(props) => (props.$bold ? "700" : null)};
    text-align: ${(props) => (props.$text_align ? "center" : "start")};
    font-size: ${(props) => (props.$caption ? "10px" : null)};

    ${(props) => props.theme.breakpoints.down("lg")} {
      font-size: ${(props) => (props.$caption ? "10px" : "14px")};
    }

    ${(props) => props.theme.breakpoints.down("tablet")} {
      font-size: ${(props) => (props.$caption ? "10px" : "12px")};
    }
  }
`);

// Grid Components //
export const DialogContentGrid = styled(Grid)`
  && {
    background: ${Color.lightPurple};
    border-radius: 0.75rem;
    margin-bottom: ${(props) => (props.$margin_bottom ? "1rem" : null)};
    margin-right: ${(props) => (props.$margin_right ? "2rem" : null)};
  }
`;

// Button Component
export const DismissButton = styling(Button)((props) => ({
  width: "12.75rem",
  color: Color.white,
  background: Color.hiredlyPurple,
  height: "2.75rem",
  borderRadius: "2rem",
  fontWeight: "bold",
  textTransform: "capitalize",

  "&:hover": {
    background: Color.darkPurple,
  },
}));

// Icon Components //
export const WorkIconStyled = styled(WorkIcon)`
  && {
    color: ${Color.hiredlyPurple};
    width: 1.5rem;
    height: 1.5rem;
  }
`;

export const WorkOffIconStyled = styled(WorkOffIcon)`
  && {
    width: 1.5rem;
    height: 1.5rem;
    color: ${Color.hiredlyPurple};
  }
`;

// Dialog Components //
export const DialogStyled = styled(Dialog)`
  && {
    & .MuiPaper-root {
      border-radius: 20px;
      padding: 1.25rem;
      max-width: 47.5rem;
    }
  }
`;

export const DialogTitleStyled = styled(DialogTitle)`
  && {
    & .MuiTypography-root {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
    }

    font-size: ${FontSize.desktopHeadline};
    text-align: center;
  }
`;

export const DialogContentStyled = styled(DialogContent)`
  && {
    padding: 8px 0;
    margin: 12px 0;
  }
`;

export const DialogActionStyled = styled(DialogActions)`
  && {
    justify-content: center;
  }
`;
