import ExclamationTriangleIcon from "@heroicons/react/24/outline/ExclamationTriangleIcon";
import ShieldExclamationIcon from "@heroicons/react/24/outline/ShieldExclamationIcon";
import XMarkIcon from "@heroicons/react/24/solid/XMarkIcon";
import { styled as styling } from "@mui/material/styles";
import Color from "../../../assets/colors";

export const DialogContainer = styling("div")((props) => ({
  position: "sticky",
  top: "64px",

  zIndex: "1000",

  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "0.5rem",

  padding: "1.25rem 0.5rem",

  width: "100%",
  background: " rgba(253, 243, 223, 1)",

  "@media (max-width: 1280px)": {
    top: props.isHomepage ? "128px" : "56px",
    alignItems: "flex-start",
  },

  "@media (max-width: 640px)": {
    top: props.isHomepage ? "120px" : "56px",
  },
}));

export const TextContainer = styling("p")({
  color: Color.black,
  display: "flex",
  alignItems: "center",
  flexWrap: "wrap",
  justifyContent: "center",

  gap: "0.25rem",
  fontSize: "1rem",
  letterSpacing: "0.15px",
  textWrap: "wrap",
  textAlign: "center",

  margin: "0",

  "@media (max-width: 1280px)": {
    flexDirection: "column",
    fontSize: "0.875rem",
  },
});

export const MobileContainer = styling("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-start",

  padding: "0 1.25rem",
});

export const InnerContainer = styling("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "0.25rem",
});

export const ImageStyled = styling("img")({
  margin: "0.25rem",
});

export const PurpleText = styling("span")({
  color: Color.hiredlyPurple,
  fontWeight: "700",
  display: "inline",
});

export const BoldText = styling("span")({
  fontWeight: "700",
});

export const CloseButton = styling("button")({
  background: "none",
  border: "none",
  padding: "0",
  margin: "0.125rem 0 0 0",
  outline: "none",

  "&:hover": {
    cursor: "pointer",
  },
});

export const CloseIcon = styling(XMarkIcon)({
  width: "1.35rem",
  height: "1.35rem",
  color: "#222222",
});

export const WarningIcon = styling(ExclamationTriangleIcon)({
  width: "1.35rem",
  height: "1.35rem",
  color: Color.hiredlyPurple,
});

export const ShieldWarningIcon = styling(ShieldExclamationIcon)({
  width: "1.35rem",
  height: "1.35rem",
  color: "rgba(228, 189, 50, 1)",
});
