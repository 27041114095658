export const FontSize = {
  desktopBannerTitle: "50px",
  desktopDisplay: "36px",
  desktopDisplay2: "32px",
  desktopDisplay3: "28px",
  desktopHeader: "26px",
  desktopTitle1: "24px",
  desktopTitle2: "22px",
  desktopHeadline: "20px",
  desktopHeadline2: "18px",
  desktopBody: "16px",
  desktopCaption: "14px",
  badge: "11px",
  bold: "bold",
  regular: "regular",
  light: "lighter",
  italic: "italic",
};
