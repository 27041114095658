import { useClickAway } from "@uidotdev/usehooks";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllStateRegions } from "../../../redux/actions/user_action";
import {
  DownArrowIcon,
  InputStyled,
  SelectChildren,
  SelectContainer,
  SelectDropdown,
  SelectHeader,
  SelectOption,
  SelectParent,
  useStyles,
} from "./styles";

function SharedStateDropdown(props) {
  const { page, value, handleChange } = props;

  const dispatch = useDispatch();

  const classes = useStyles();

  const placeholderText =
    page === "onboarding" ? "Insert Your Current Location" : "Please Select";

  const stateList = useSelector((state) => state.user.stateRegions);
  const myList = stateList.my;
  const sgList = stateList.sg;

  const allLocations = Object.entries(stateList).flatMap(
    ([country, locations]) => locations.map(({ title, id }) => ({ title, id }))
  );

  const [open, setOpen] = useState(false);
  const [openSecondary, setOpenSecondary] = useState("");
  const [currentValue, setCurrentValue] = useState(value);
  const [searchState, setSearchState] = useState("");

  const dropdownRef = useClickAway(() => {
    setOpen(false);
  });

  function handleDropdown() {
    // if (searchState !== "") return setOpen(true);
    if (open) setOpenSecondary("");

    setOpen(!open);
  }

  function handleSecondaryDropdown(state) {
    if (openSecondary === state) return setOpenSecondary("");
    if (state === "overseas")
      return handleSelectOption("", { id: null, title: "Overseas" });

    setOpenSecondary(state);
  }

  function handleSelectOption(e, state) {
    setCurrentValue(state?.title);
    setSearchState("");
    handleChange(e, state?.title);
    handleDropdown();
  }

  function handleSearch(event) {
    if (open === false) setOpen(true);
    setSearchState(event.target.value);
  }

  function getFilteredState(searchTerm) {
    if (!searchTerm || searchTerm === "") return allLocations;

    return allLocations
      .filter(({ id, title }) =>
        title.toLowerCase().includes(searchTerm.toLowerCase())
      )
      .sort((a, b) => {
        const aStartsWith = a?.title
          .toLowerCase()
          .startsWith(searchTerm.toLowerCase());
        const bStartsWith = b?.title
          .toLowerCase()
          .startsWith(searchTerm.toLowerCase());
        if (aStartsWith && !bStartsWith) return -1;
        if (!aStartsWith && bStartsWith) return 1;
        return 0;
      });
  }

  const filteredState = getFilteredState(searchState);

  useEffect(() => {
    if (Object.keys(stateList).length === 0) {
      dispatch(getAllStateRegions());
    }
  }, [dispatch, stateList]);

  return (
    <SelectContainer
      className={classes.scrollBar}
      page={page}
      ref={dropdownRef}
    >
      <SelectHeader onClick={handleDropdown} page={page}>
        <InputStyled
          label="State / Region"
          variant="standard"
          onChange={handleSearch}
          placeholder={placeholderText}
          page={page}
          value={open ? searchState : currentValue}
        />
        <DownArrowIcon selected={open} />
      </SelectHeader>

      {filteredState?.length > 0 ? (
        <SelectDropdown open={open} page={page}>
          {searchState === "" ? (
            <>
              <SelectParent
                selected={openSecondary === "my"}
                onClick={() => handleSecondaryDropdown("my")}
              >
                Malaysia
                <DownArrowIcon selected={openSecondary === "my"} />
              </SelectParent>
              <SelectChildren open={openSecondary === "my"}>
                {myList?.map((state, index) => (
                  <SelectOption
                    key={index}
                    selected={currentValue === state.title}
                    onClick={(e) => handleSelectOption(e, state)}
                  >
                    {state?.title}
                  </SelectOption>
                ))}
              </SelectChildren>

              <SelectParent
                selected={openSecondary === "sg"}
                onClick={() => handleSecondaryDropdown("sg")}
              >
                Singapore
                <DownArrowIcon selected={openSecondary === "sg"} />
              </SelectParent>
              <SelectChildren open={openSecondary === "sg"}>
                {sgList?.map((state, index) => (
                  <SelectOption
                    key={index}
                    selected={currentValue === state.title}
                    onClick={(e) => handleSelectOption(e, state)}
                  >
                    {state?.title}
                  </SelectOption>
                ))}
              </SelectChildren>

              <SelectParent
                selected={
                  openSecondary === "overseas" || currentValue === "Overseas"
                }
                onClick={() => handleSecondaryDropdown("overseas")}
              >
                Overseas
              </SelectParent>
            </>
          ) : (
            <>
              {filteredState?.map((state, index) => (
                <SelectOption
                  key={index}
                  search
                  selected={currentValue === state.title}
                  onClick={(e) => handleSelectOption(e, state)}
                >
                  {state.title}
                </SelectOption>
              ))}
            </>
          )}
        </SelectDropdown>
      ) : null}
    </SelectContainer>
  );
}

export default SharedStateDropdown;
