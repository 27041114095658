import { Box, Dialog, Typography } from "@mui/material";
import { styled as styling } from "@mui/material/styles";
import Color from "../../../assets/colors";

export const DialogStyled = styling(Dialog)((props) => ({
  "& .MuiDialog-paper": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: "328px",
    paddingInline: "24px",
    paddingBlock: "48px 180px",
    borderRadius: "24px",
    border: `2px solid ${Color.black}`,
    [props.theme.breakpoints.up(768)]: {
      maxWidth: "670px",
      paddingBlock: "48px 100px",
    },
  },
}));

export const HeaderTitle = styling(Typography)((props) => ({
  fontSize: "1.25rem",
  fontWeight: 700,
  marginBottom: "40px",

  [props.theme.breakpoints.up(768)]: {
    fontSize: "1.563rem",
  },
}));

export const ContentWrapper = styling(Box)((props) => ({
  display: "flex",
  flexDirection: "column",
  gap: "24px",

  [props.theme.breakpoints.up(768)]: {
    flexDirection: "row",
  },
}));

export const ContentContainer = styling(Box)((props) => ({
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  gap: "16px",
  borderRadius: "8px",
  border: `2px solid ${Color.grey}`,
  padding: "24px 16px",
  transition: "border 0.3s",

  "&:hover": {
    border: `2px solid ${Color.hiredlyPurple}`,
  },

  [props.theme.breakpoints.up(768)]: {
    flexDirection: "column",
    gap: "24px",
    width: "280px",
  },
}));

export const ResumeBuilderImage = styling("img")((props) => ({
  maxWidth: "110px",
  objectFit: "cover",

  [props.theme.breakpoints.up(768)]: {
    maxWidth: "100%",
    flex: "auto",
  },
}));

export const NoResumeImage = styling("img")((props) => ({
  maxWidth: "110px",
  objectFit: "cover",

  [props.theme.breakpoints.up(768)]: {
    maxWidth: "100%",
    flex: "auto",
    height: "108px",
  },
}));

export const ContentTextContainer = styling(Box)((props) => ({
  width: "124px",

  [props.theme.breakpoints.up(768)]: {
    width: "auto",
    textAlign: "center",
  },
}));

export const Title = styling(Typography)((props) => ({
  fontSize: "0.875rem",
  color: Color.darkPurple,
  fontWeight: 700,

  [props.theme.breakpoints.up(768)]: {
    fontSize: "1.125rem",
  },
}));

export const ContentText = styling(Typography)((props) => ({
  fontSize: "0.75rem",
  color: Color.darkPurple,

  [props.theme.breakpoints.up(768)]: {
    fontSize: "1rem",
    textWrap: "balance",
  },
}));
