import ChevronDownIcon from "@heroicons/react/20/solid/ChevronDownIcon";
import { InputBase } from "@mui/material";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/Button";
import { styled as styling } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import Color from "../../../assets/colors";

// Scrollbar Component //
export const useStyles = makeStyles((theme) => ({
  scrollBar: {
    "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
      backgroundColor: "transparent",
      width: 3,
      overflow: "auto",
    },
    "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
      borderRadius: 8,
      backgroundColor: Color.hiredlyPurple,
      overflow: "auto",
    },
    "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
      borderRadius: 8,
      backgroundColor: Color.lightHiredlyPurple,
      overflow: "auto",
    },
    "&::-webkit-scrollbar-track": {
      marginTop: "1.5rem",
      marginBottom: "1.5rem",
      height: "3.25rem",
    },
  },
}));

// Select Component //
export const SelectContainer = styling("div")((props) => ({
  position: "relative",
  width: "100%",
  maxWidth: "100%",

  ...(props.page === "onboarding" && {
    minWidth: "320px",
    maxWidth: "320px",

    "@media (max-width: 640px)": {
      maxWidth: "100%",
    },
  }),
}));

export const SelectHeader = styling("div")((props) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "0.5rem",

  ...(props.page === "onboarding" && {
    justifyContent: "center",
    borderBottom: "1px solid" + Color.black,
  }),

  ...(props.page === "profile-overview" && {
    border: "2px solid rgba(0, 0, 0, 0.38)",
    borderRadius: "6.25rem",
    justifyContent: "flex-start",
    padding: "11px 8px 10px 14px",
  }),

  ...(props.page === "secondary-onboarding" && {
    border: "1px solid" + Color.grey,
    borderRadius: "0.5rem",
    justifyContent: "flex-start",
    padding: "15px 8px 16px 14px",

    ":hover": {
      border: "1px solid" + Color.black,
    },
  }),
}));

export const InputStyled = styling(InputBase)((props) => ({
  fontSize: "1rem",
  width: "100%",

  input: {
    padding: "0",
  },

  ...(props.page === "onboarding" && {
    "input::placeholder": {
      fontStyle: "italic",
      textAlign: "center",
    },

    "@media (max-width: 1280px)": {
      "input::placeholder": {
        textAlign: "left",
      },
    },
  }),

  ...(props.page === "secondary-onboarding" && {
    input: {
      color: "rgba(0, 0, 0, 0.68)",
      padding: "0",
    },

    "input::placeholder": {
      opacity: "0.9",
    },
  }),

  "@media (max-width: 640px)": {
    fontSize: "0.875rem",
  },
}));

export const SelectDropdown = styling("div")((props) => ({
  position: "absolute",
  zIndex: "10",

  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",

  backgroundColor: Color.white,
  border: "1px solid transparent",
  borderRadius: "0.5rem",

  maxHeight: "300px",
  width: "100%",
  margin: props.page === "onboarding" ? "0.625rem 0 0 0" : "0.25rem 0 0 0",
  overflow: "clip",

  opacity: "0",
  pointerEvents: "none",
  transition: "all 0.15s ease-in-out",

  ...(props.open && {
    border: "1px solid" + Color.secondaryGrey,
    pointerEvents: "auto",
    opacity: "1",
  }),

  "@media (max-width: 640px)": {
    maxHeight: props.page === "onboarding" ? "28svh" : "300px",
    flexGrow: "1",
  },
}));

export const SelectParent = styling(ButtonBase)((props) => ({
  color: "rgba(0, 0, 0, 0.87)",
  backgroundColor: Color.white,

  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",

  fontFamily: "Inter",
  fontSize: "1rem",
  fontWeight: "400",
  lineHeight: "1.25rem",
  letterSpacing: "0.112px",
  whiteSpace: "nowrap",
  textTransform: "initial",

  height: "48px",
  width: "100%",
  padding: "0.875rem 0.5rem 0.875rem 1rem",

  transition: "all 0.15s ease-in-out",

  ...(props.selected && {
    backgroundColor: Color.lightHiredlyPurple,
    fontWeight: "700",
  }),

  "&:hover": {
    cursor: "pointer",
  },

  "@media (max-width: 1279px)": {
    fontSize: "0.875rem",
  },

  "@media (max-width: 640px)": {
    height: "42px",
    padding: "0.5rem 0.5rem 0.5rem 1rem",
  },
}));

export const SelectChildren = styling(Box)((props) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",

  backgroundColor: Color.white,

  maxHeight: "0",
  width: "100%",
  overflowX: "clip",
  overflowY: "scroll",

  opacity: "0",
  transition: "all 0.2s ease-in-out",

  ...(props.open && {
    maxHeight: "100%",
    marginTop: "0",
    opacity: "1",
  }),
}));

export const SelectOption = styling(ButtonBase)((props) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",

  color: "rgba(0, 0, 0, 0.87)",
  fontSize: "1rem",
  fontWeight: "400",
  lineHeight: "1.25rem",
  letterSpacing: "0.112px",
  textTransform: "initial",
  textWrap: "nowrap",

  width: "100%",
  padding: props?.search
    ? "0.875rem 0.5rem 0.875rem 0.5rem"
    : "0.875rem 0.5rem 0.875rem 2rem",

  "&:hover": {
    backgroundColor: Color.backgroundGrey,
  },

  ...(props.selected && {
    backgroundColor: Color.lightHiredlyPurple,
  }),

  "@media (max-width: 640px)": {
    fontSize: "0.875rem",
    padding: "0.5rem 0.5rem 0.5rem 2rem",
  },
}));

export const DownArrowIcon = styling(ChevronDownIcon)((props) => ({
  color: Color.darkGrey,

  height: "20px",
  width: "20px",
  margin: "-0.5rem 0",

  transform: props.selected ? "rotate(-180deg)" : "rotate(0deg)",
  transition: "all 0.15s ease-in-out",
}));
