import { store } from "../redux/stores/store";

const triggerSnackBar = (hideSnackBar, showSnackBar, param) => {
  if (store.getState().snackbar.showSnackBar) {
    hideSnackBar().then((hideResponse) => {
      setTimeout(() => {
        showSnackBar(param);
      }, 500);
    });
  } else {
    showSnackBar(param);
  }
};

export { triggerSnackBar };
