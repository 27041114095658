import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EyeIcon from "@heroicons/react/24/outline/EyeIcon";
import EyeSlashIcon from "@heroicons/react/24/outline/EyeSlashIcon";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { styled as styling } from "@mui/material/styles";
import { withTheme } from "@mui/styles";
import { animated } from "react-spring";
import styled, { css } from "styled-components";
import Color from "../../../assets/colors";

// Button Component //
export const LogInButton = withTheme(styled(Button)`
  && {
    font-weight: 700;
    height: 42px;
    text-transform: capitalize;
    font-size: 1rem;
  }
`);

// Grid Components //
export const HiredlyLogoGrid = withTheme(styled(Grid)`
  position: relative;
  height: 1.125rem;

  ${(props) => props.theme.breakpoints.down("tabletS")} {
    height: 1.2rem;
    width: 8.5rem;
  }
`);

export const EmailContainer = withTheme(styled(Grid)`
  && {
    width: 100%;
  }
`);

export const PasswordContainer = withTheme(styled(Grid)`
  && {
    width: 100%;
    margin-top: 20px;
  }
`);

export const ForgotPasswordContainer = withTheme(styled(Grid)`
  && {
    cursor: pointer;
    display: flex;
    justify-content: end;
    margin-bottom: 1rem;
  }
`);

export const SSOLoginContainer = withTheme(styled(animated(Grid))`
  && {
    display: flex;
    justify-content: center;
    gap: 1.5rem;

    ${(props) => props.theme.breakpoints.down("tablet")} {
      margin: 1rem 0;
    }
  }
`);

export const EmailLoginFormContainer = styled(Grid)`
  && {
    margin: 20px 0;
  }
`;

export const CustomDividerContainer = styled(Grid)`
  && {
    display: flex;
    margin: 20px 0;
    align-items: center;
  }
`;

export const SubmissionContainer = styled(Grid)`
  && {
    display: flex;
    flex-direction: column;
  }
`;

export const SignUpContainer = styled(Grid)`
  && {
    text-align: center;
    display: flex;
    margin: 1rem 0;
    gap: 0.25rem;
    align-items: baseline;
  }
`;

export const FormTitle = styling(Typography)({
  fontSize: "1.563rem",
  fontWeight: 700,
});

// Typography Components //
export const LabelStyled = withTheme(styled(Typography)`
  && {
    white-space: nowrap;
    font-weight: ${(props) => (props.$caption ? null : "700")};
    cursor: default;
    font-size: ${(props) => (props.$caption ? "0.875rem" : "1.5rem")};
  }
`);

export const LabelDivider = withTheme(styled(Typography)`
  && {
    font-size: 0.875rem;
    color: ${Color.secondaryGrey};
    margin: 0 12px;
    text-align: center;

    ${(props) => props.theme.breakpoints.down("tablet")} {
      font-size: 0.75rem;
      margin: 0 0.25rem;
    }
  }
`);

export const ActionLabelStyled = withTheme(styled(Typography)`
  && {
    font-size: 0.875rem;
    white-space: nowrap;
    font-weight: bold;
    color: ${Color.hiredlyPurple};
    cursor: pointer;
    text-transform: capitalize;
  }
`);

// Dialog Components //
export const LoginDialog = withTheme(styled(Dialog)`
  && {
    .MuiDialog-container .MuiPaper-root {
      border-radius: 0.5rem;
    }

    & .MuiBackdrop-root {
      backdrop-filter: blur(16px);
      background-color: #7a7a7a66;
    }

    .MuiDialog-paper {
      max-width: 400px;
      border: 2px solid black;
    }

    .center {
      display: flex;
      justify-content: center;
    }
  }
`);

export const DialogContentStyled = withTheme(styled(DialogContent)`
  padding-inline: 32px !important;
  padding-block: 24px 32px !important;
`);

// TextField Components //
export const TextInput = withTheme(styled(TextField)`
  && {
    display: flex;
    max-width: 100%;

    & .MuiInputBase-root {
      height: 50px;
      padding: 0 1px;
      transform: translate(0, 2px);
    }

    & .MuiInputBase-input {
      padding-left: 1.125rem;
    }

    & .MuiFilledInput-input:-webkit-autofill {
      height: 12px;
    }

    & .MuiFilledInput-underline:before {
      display: none;
    }

    & .MuiFilledInput-underline:after {
      display: none;
    }

    &
      .MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel) {
      margin-top: 0;
    }

    ${(props) =>
      (props.type == "email" ||
        props.type == "password" ||
        props.password_shown == "true") &&
      css`
        & .MuiInputLabel-root {
          transform: translate(20px, 15px) scale(1);
        }

        & .MuiInputLabel-filled.MuiInputLabel-shrink {
          transform: translate(20px, 10px) scale(0.75);
        }
      `}
  }
`);

// Icon Components //
export const FontAwesomeIconStyled = withTheme(styled(FontAwesomeIcon)`
  && {
    display: block;
    max-width: 1rem;
    margin-right: 10px;

    ${(props) => props.theme.breakpoints.down("mobileL")} {
      display: flex;
      max-width: 1rem;
    }
  }
`);

export const CloseIconContainer = styled(IconButton)`
  && {
    //
  }
`;

export const VisibilityIconStyled = styled(EyeIcon)`
  && {
    cursor: pointer;
    width: 1.25rem;
    height: 1.25rem;
  }
`;

export const VisibilityOffIconStyled = styled(EyeSlashIcon)`
  && {
    cursor: pointer;
    width: 1.25rem;
    height: 1.25rem;
  }
`;

// Progress Components //
export const CircularProgressStyled = styled(CircularProgress)`
  && {
    &.MuiCircularProgress-root {
      //
    }
  }
`;

export const CircularProgressWhite = styled(CircularProgress)`
  && {
    &.MuiCircularProgress-root {
      width: 24px !important;
      height: 24px !important;
      color: white !important;
    }
  }
`;

// Image Component //
export const LogoStyled = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

// Span Component //
export const SpanStyled = styled.span`
  min-width: 170px;
  text-align: left;
`;

// Box Components //
export const CustomDivider = withTheme(styled(Box)`
  && {
    flex: 1%;
    border-bottom: 1px solid ${Color.secondaryGrey};
  }
`);

export const SocialSpinnerContainer = styled(Box)`
  && {
    //
  }
`;

export const HeaderDialog = styled(Box)`
  && {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid hsla(0, 0%, 0%, 0.2);
    padding-inline: 32px 1.5rem;
    padding-block: 32px 24px;
  }
`;

export const LinkedInButton = styled(Box)`
  && {
    width: 3rem;
    height: 3rem;
    background-color: ${Color.linkedInBlue};
    border-radius: 50%;
    cursor: pointer;
    display: grid;
    justify-items: center;
    align-items: center;
    border: 1px solid ${Color.black};
    box-shadow: 2px 2px 0 ${Color.black};
  }
`;

export const GoogleButton = withTheme(styled(Box)`
  && {
    width: 3rem;
    height: 3rem;
    background-color: ${Color.white};
    border-radius: 50%;
    cursor: pointer;
    justify-items: center;
    align-items: center;
    border: 1px solid ${Color.black};
    box-shadow: 2px 2px 0 ${Color.black};
    display: ${(props) => (props.$hide_button === "true" ? "none" : "grid")};
  }
`);
