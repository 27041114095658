import { isEmpty } from "lodash";
import { useRouter } from "next/router";
import posthog from "posthog-js";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as config from "../../../config/config";
import { sendTrackingEvent } from "../../../helpers/tracking_management";
import {
  updateResumeConfirmationDialog,
  updateSignUpProfileDialog,
} from "../../../redux/actions/dialog_action";
import { getUser } from "../../../redux/actions/user_action";
import {
  ContentContainer,
  ContentText,
  ContentTextContainer,
  ContentWrapper,
  DialogStyled,
  HeaderTitle,
  NoResumeImage,
  ResumeBuilderImage,
  Title,
} from "./styles";

export default function ResumeConfirmationDialog() {
  const router = useRouter();
  const dispatch = useDispatch();
  const open = useSelector(
    (state) => state.dialog?.showResumeConfirmationDialog
  );
  const user = getUser();

  function handleOpenProfilePreviewDialog() {
    sendTrackingEvent({
      event: "CE_click-have-resume-onboarding",
    });
    posthog.capture("onboarding_resume_click");
    // @ts-ignore
    dispatch(updateSignUpProfileDialog(true));
  }

  function handleOnboardingClick() {
    posthog.capture("onboarding_no_resume_click");

    sendTrackingEvent({
      event: "CE_click-no-resume-onboarding",
    });

    // @ts-ignore
    dispatch(updateResumeConfirmationDialog(false));
    router.replace("/onboarding");
  }

  const isDropResume =
    router.pathname.includes("/drop-resume") ||
    router.pathname.includes("/drop-resume-hiredly-x") ||
    router.pathname.includes("/update-resume") ||
    router.pathname.includes("/apply");

  const showDialog =
    !isEmpty(user) &&
    isEmpty(user?.resume) &&
    !user?.onboardingComplete &&
    !isDropResume;

  useEffect(() => {
    if (showDialog) {
      setTimeout(() => {
        dispatch(updateResumeConfirmationDialog(true));
      }, 1000);
    } else {
      dispatch(updateResumeConfirmationDialog(false));
    }
  }, [user, isDropResume]);

  return (
    showDialog && (
      <DialogStyled open={open}>
        <HeaderTitle>Do you have a resume?</HeaderTitle>
        <ContentWrapper>
          <ContentContainer onClick={handleOpenProfilePreviewDialog}>
            <ResumeBuilderImage
              alt="Resume builder image"
              src={`${config.assetDomain}/images/hiredly/resume+builder.png`}
            />
            <ContentTextContainer>
              <Title>I have a Resume</Title>
              <ContentText>
                Upload your resume and we will handle the rest for you.
              </ContentText>
            </ContentTextContainer>
          </ContentContainer>

          <ContentContainer onClick={handleOnboardingClick}>
            <NoResumeImage
              alt="No resume image"
              src={`${config.assetDomain}/images/hiredly/no+resume.png`}
            />
            <ContentTextContainer>
              <Title>I don’t have a Resume</Title>
              <ContentText>
                Tell us about yourself, we'll recommend you the right jobs.
              </ContentText>
            </ContentTextContainer>
          </ContentContainer>
        </ContentWrapper>
      </DialogStyled>
    )
  );
}
