import styled from "styled-components";
import withTheme from "@mui/styles/withTheme";
import CircularProgress from "@mui/material/CircularProgress";

export const CircularProgressStyled = withTheme(styled(CircularProgress)`
  && {
    &.MuiCircularProgress-root {
      width: ${(props) => `${props.width}px !important`};
      height: ${(props) => `${props.height}px !important`};
    }
  }
`);

export const CircularProgressWhite = withTheme(styled(CircularProgress)`
  && {
    &.MuiCircularProgress-root {
      width: ${(props) => `${props.width}px !important`};
      height: ${(props) => `${props.height}px !important`};
      color: white !important;
    }
  }
`);
