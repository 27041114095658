import React, { forwardRef, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as config from "../../../../config/config";
import { useUploadResume } from "../../../../helpers/resume_upload";
import { uploadResume } from "../../../../redux/actions/user_action";
import {
  CloseIconStyled,
  DoneOutlinedIconStyled,
  DragResumeElement,
  EmptyContainer,
  ErrorContainer,
  ErrorOutlineIconStyled,
  FilenameText,
  ImportButton,
  InputStyled,
  InvalidResumeText,
  LinearProgressStyled,
  LottieStyled,
  OuterContainer,
  PercentageText,
  SupportFileText,
  UploadFileOutlinedIconStyled,
} from "./styles";
import { ResumeDropzoneProps } from "./types";

const ResumeDropzone = forwardRef(
  (props: ResumeDropzoneProps, ref: React.Ref<any>) => {
    const { complete, setComplete } = props;
    const [dragActive, setDragActive] = React.useState<boolean>(false);
    const [fileName, setFileName] = React.useState<string>("");
    const [validResume, setValidResume] = useState<boolean>(false);
    const [triggerLoading, setTriggerLoading] = React.useState<boolean>(false);

    const inputRef = React.useRef(null);
    const dispatch: any = useDispatch();
    const [progress, setProgress] = useState<any>(0);

    useEffect(() => {
      const timer = setInterval(() => {
        if (triggerLoading) {
          setProgress((oldProgress: number) => {
            if (oldProgress === 100) {
              setComplete(true);
            }
            const diff = Math.random() * 10;
            return Math.min(oldProgress + diff, 100);
          });
        }
      }, 50);

      return () => {
        clearInterval(timer);
      };
    }, [triggerLoading]);

    // Triggers when file is selected with click
    const handleFileChange = async function (e: any) {
      setValidResume(true);
      setFileName(e.target.files[0]?.name);

      const file = e.target.files[0];
      if (file?.name === undefined) {
        setComplete(false);
        dispatch(uploadResume(""));
        setValidResume(false);
        setTriggerLoading(false);
        return;
      }

      if (parseFloat((file?.size / 1024 / 1024).toFixed(4)) > 2) {
        setValidResume(false);
        dispatch(uploadResume(""));
        setComplete(false);
        setTriggerLoading(false);
        return;
      }

      setProgress(0);
      setComplete(false);
      e.preventDefault();
      if (e.target.files && e.target.files[0]) {
        setFileName(e.target.files[0].name);

        setTriggerLoading(true);

        const isUploadSuccess = await useUploadResume(file, dispatch);
      }
    };

    // Triggers the input when the browse button is clicked
    const onBrowseClicked = () => {
      inputRef.current.click();
    };

    // Handle drag events
    const handleDrag = function (e: any) {
      e.preventDefault();
      e.stopPropagation();

      if (e.type === "dragenter" || e.type === "dragover") {
        if (!dragActive) {
          setDragActive(true);
        }
      } else if (e.type === "dragleave") {
        if (dragActive) {
          setDragActive(false);
        }
      }
    };

    // Triggers when file is dropped
    const handleDrop = function (e: any) {
      e.preventDefault();
      e.stopPropagation();

      if (dragActive) {
        setDragActive(false);
      }
    };

    return (
      <OuterContainer
        ref={ref}
        container
        direction={"row"}
        justifyContent="center"
        alignItems="center"
      >
        <EmptyContainer
          container
          justifyContent="center"
          sx={{ width: { xs: "100%", tablet: "40%" } }}
        >
          <LottieStyled
            play={true}
            path={`${config.assetDomain}/lottie/upload-file.json`}
          />
        </EmptyContainer>

        <EmptyContainer
          container
          justifyContent="center"
          alignContent="center"
          sx={{ width: { xs: "100%", tablet: "50%" } }}
        >
          <EmptyContainer
            container
            sx={{ flexDirection: "column", alignItems: "center" }}
          >
            <ImportButton onClick={onBrowseClicked}>Browse files</ImportButton>
            <SupportFileText>
              Support file type: .pdf, .doc, .docx
            </SupportFileText>
          </EmptyContainer>
          {fileName && (
            <EmptyContainer
              container
              flexDirection="row"
              alignItems="center"
              justifyContent={{ xs: "center", tablet: "space-between" }}
              sx={{ mt: "10px", height: "auto", gap: "5px" }}
            >
              <UploadFileOutlinedIconStyled />

              <EmptyContainer
                container
                flexDirection="column"
                justifyContent="center"
                sx={{ width: { xs: "70%", tablet: "85%" } }}
              >
                <EmptyContainer container justifyContent="space-between">
                  <EmptyContainer
                    container
                    alignItems="center"
                    sx={{
                      maxWidth: "140px",
                      overflow: "hidden",
                      marginBottom: complete ? "" : "5px",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <FilenameText>{`${fileName?.slice(
                      0,
                      16
                    )}...`}</FilenameText>
                  </EmptyContainer>

                  {complete && validResume ? (
                    <DoneOutlinedIconStyled />
                  ) : !validResume ? (
                    <CloseIconStyled />
                  ) : (
                    <PercentageText>{`${parseInt(progress)}%`}</PercentageText>
                  )}
                </EmptyContainer>

                {!complete && validResume ? (
                  <LinearProgressStyled
                    variant="determinate"
                    value={progress}
                  />
                ) : null}
              </EmptyContainer>
              {!validResume && (
                <ErrorContainer>
                  <ErrorOutlineIconStyled />
                  <InvalidResumeText>
                    File is too large. Maximum file size is 2MB.
                  </InvalidResumeText>
                </ErrorContainer>
              )}
            </EmptyContainer>
          )}
          <InputStyled
            ref={inputRef}
            accept=".pdf,.docx,.doc"
            type="file"
            onChange={handleFileChange}
          />
        </EmptyContainer>

        {dragActive && (
          <DragResumeElement
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          />
        )}
      </OuterContainer>
    );
  }
);

export default ResumeDropzone;
