import { CardContentList, CardContentText } from "./styles";
import { Education, WorkExperience } from "./types";

export function WorkExperienceCardContent(props: {
  workExperience: WorkExperience;
}) {
  const { workExperience } = props;
  return (
    <CardContentList>
      {workExperience?.jobDescription?.length > 0 ? (
        workExperience.jobDescription.split("•")?.map((description, index) => {
          // NOTE: The first element will be empty sometime
          // Therefore, check for length before rendering
          if (description?.length > 0) {
            return <CardContentText key={index}>{description}</CardContentText>;
          }
        })
      ) : (
        <CardContentText>-</CardContentText>
      )}
    </CardContentList>
  );
}

export function EducationCardContent(props: { education: Education }) {
  const { education } = props;
  return (
    <CardContentList>
      {education?.description?.length > 0 ? (
        education.description.split("•")?.map((description, index) => {
          // NOTE: The first element will be empty sometime
          // Therefore, check for length before rendering
          if (description?.length > 0) {
            return <CardContentText key={index}>{description}</CardContentText>;
          }
        })
      ) : (
        <CardContentText>-</CardContentText>
      )}
    </CardContentList>
  );
}
