/*
Data State related logic
*/
import { useRouter } from "next/router";
import posthog from "posthog-js";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendTrackingEvent } from "../../../helpers/tracking_management";
import useTriggerSnackbar from "../../../hooks/useTriggerSnackbar";
import {
  updateProfilePreviewDialog,
  updateResumeFeedbackDialog,
} from "../../../redux/actions/dialog_action";
import {
  fetchParsedResumeSections,
  updateOnboardingResume,
} from "../../../redux/actions/user_action";
import { UserProfileData } from "./types";
import {
  isValidCertificate,
  isValidEducation,
  isValidLanguage,
  isValidWorkExperience,
} from "./validation";

const PROGRESS_BAR_LOADING_INTERVAL = 2700;
const REFETCH_INTERVAL = 5500;

// Refactor / Optimize Note:
// Finish progress bar when data is fetched
// Try calling several times if data is empty before progress bar finished
export default function useResumeSections() {
  const dispatch: any = useDispatch();
  const router = useRouter();
  const [previewData, setPreviewData] = useState<UserProfileData | null>(null);
  const [editData, setEditData] = useState<UserProfileData | null>(null);
  const [progress, setProgress] = useState(0);
  const [selectedSkills, setSelectedSkills] = useState<string[]>([]);
  const [isEmpty, setIsEmpty] = useState(false);
  const [triggerSnackbarFunc] = useTriggerSnackbar();
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const [shouldShowError, setShouldShowError] = useState(false);

  let intervalId: NodeJS.Timer;

  const profilePreviewDialogOpen = useSelector(
    (state: any) => state?.dialog?.showProfilePreviewDialog
  );

  useEffect(() => {
    if (profilePreviewDialogOpen) {
      init();
    }
  }, [profilePreviewDialogOpen, shouldRefetch]);

  async function init() {
    let isServerError = false;
    let progressRef = 0;
    if (intervalId) {
      clearInterval(intervalId);
    }

    // NOTE: Receive Empty Resume, BE need more time to process
    // Therefore, set interval to wait more time than normal
    const loadingInterval = shouldRefetch
      ? REFETCH_INTERVAL
      : PROGRESS_BAR_LOADING_INTERVAL;
    const loadingBase = shouldRefetch ? 5 : 10;

    while (progressRef < 100) {
      const progressBarIncrement = Math.random() * loadingBase;
      setProgress((prev) => {
        progressRef = prev + progressBarIncrement;
        return prev + progressBarIncrement;
      });

      let res = await dispatch(fetchParsedResumeSections());

      // NOTE: Currently there's no way to determine BE empty response
      // is because empty resume or just getting empty response
      // from Ashley as it's still processing
      // Therefore, continue until the progress bar finish
      if (res?.type === "FETCH_PARSED_RESUME_SECTIONS_FAILED") {
        isServerError = true;

        // Wait certain time instead of retry immediately
        await new Promise((resolve) => setTimeout(resolve, loadingInterval));
        continue;
      }

      // If getting empty response, try again
      if (res?.type === "FETCH_EMPTY_PARSED_RESUME_SECTIONS") {
        isServerError = false;
        setIsEmpty(true);

        // Wait certain time instead of retry immediately
        await new Promise((resolve) => setTimeout(resolve, loadingInterval));
        continue;
      }

      // Is success, res.type === "FETCH_PARSED_RESUME_SECTIONS_SUCCESS"
      isServerError = false;
      setIsEmpty(false);
      let { parsedResumeSections } = res || {};
      setPreviewData(parsedResumeSections);
      setEditData(parsedResumeSections);
      intervalId = setInterval(() => {
        setProgress((oldProgress) => {
          return Math.min(oldProgress + 10, 100);
        });
      }, 100);
      break;
    }

    if (!previewData && progressRef >= 100 && isServerError) {
      // Done fetching but nothing response
      triggerSnackbarFunc({
        snackbarMessage: "Something went wrong, please try again later.",
        severity: "error",
      });
    }
  }

  function reFetch() {
    setProgress(0);
    setIsEmpty(false);
    setShouldRefetch(true);
  }

  function isValidData(type: keyof UserProfileData, index: number) {
    switch (type) {
      case "workExperiencesStructured":
        const workExperience = editData.workExperiencesStructured[index];
        const validateWorkExperience = isValidWorkExperience(workExperience);
        if (!validateWorkExperience.success) {
          triggerSnackbarFunc({
            snackbarMessage: validateWorkExperience.errorMessage,
            severity: "error",
          });
          return false;
        }
        return true;
      case "educationsStructured":
        const education = editData.educationsStructured[index];
        const validateEducation = isValidEducation(education);
        if (!validateEducation.success) {
          triggerSnackbarFunc({
            snackbarMessage: validateEducation.errorMessage,
            severity: "error",
          });
          return false;
        }
        return true;
      case "certifications":
        const certificate = editData.certifications[index];
        const validateCertificate = isValidCertificate(certificate);
        if (!validateCertificate.success) {
          triggerSnackbarFunc({
            snackbarMessage: validateCertificate.errorMessage,
            severity: "error",
          });
          return false;
        }
        return true;
      case "languages":
        const language = editData.languages[index];
        const validateLanguage = isValidLanguage(language);
        if (!validateLanguage.success) {
          triggerSnackbarFunc({
            snackbarMessage: validateLanguage.errorMessage,
            severity: "error",
          });
          return false;
        }
        return true;
    }
  }

  function onDataChange(
    input: any,
    updateSection: keyof UserProfileData,
    updateField: string,
    updateIndex: number
  ) {
    let newData = editData?.[updateSection]?.map((resumeSection, index) => {
      if (updateIndex === index) {
        return {
          ...resumeSection,
          [updateField]: input,
        };
      }
      return resumeSection;
    });

    setEditData((prev) => ({
      ...prev,
      [updateSection]: newData,
    }));
  }

  function onSelectResumeSection(
    resumeSection: keyof UserProfileData,
    updateIndex: number,
    select?: boolean
  ) {
    setPreviewData((prev) => {
      const newData = {
        ...prev,
        [resumeSection]: prev?.[resumeSection]?.map((field, index) => {
          if (index === updateIndex) {
            return {
              ...field,
              selected: select || !field.selected,
            };
          }
          return field;
        }),
      };

      setEditData(newData);
      return newData;
    });
  }

  function onSaveEditData(
    resumeSection: keyof UserProfileData,
    updateIndex: number
  ) {
    if (!isValidData(resumeSection, updateIndex)) return false;
    setPreviewData((prev) => ({
      ...prev,
      ...editData,
    }));
    onSelectResumeSection(resumeSection, updateIndex, true);
    return true;
  }

  function undoEditData() {
    setEditData(previewData);
  }

  function onSelectAllResumeSections() {
    let select = !isAllResumeSectionsSelected();

    if (select) {
      setSelectedSkills(previewData?.skills);
    } else {
      setSelectedSkills([]);
    }

    setPreviewData((prev) => {
      const newData = {
        ...prev,
        certifications: prev?.certifications?.map((certification) => ({
          ...certification,
          selected: select,
        })),
        workExperiencesStructured: prev?.workExperiencesStructured?.map(
          (workExperience) => ({ ...workExperience, selected: select })
        ),
        educationsStructured: prev?.educationsStructured?.map((education) => ({
          ...education,
          selected: select,
        })),
        languages: prev?.languages?.map((language) => ({
          ...language,
          selected: select,
        })),
      };

      setEditData(newData);
      return newData;
    });
  }

  function isAllResumeSectionsSelected() {
    return (
      (previewData?.workExperiencesStructured?.every(
        (workExperience) => workExperience.selected
      ) ??
        true) &&
      (previewData?.educationsStructured?.every(
        (education) => education.selected
      ) ??
        true) &&
      (previewData?.languages?.every((language) => language.selected) ??
        true) &&
      (previewData?.certifications?.every((cert) => cert.selected) ?? true) &&
      previewData?.skills?.length === selectedSkills?.length
    );
  }

  function hasResumeSectionSelected() {
    return (
      selectedSkills?.length > 0 ||
      (previewData?.workExperiencesStructured?.some(
        (workExperience) => workExperience.selected
      ) ??
        true) ||
      (previewData?.educationsStructured?.some(
        (education) => education.selected
      ) ??
        true) ||
      (previewData?.languages?.some((language) => language.selected) ?? true) ||
      (previewData?.certifications?.some((cert) => cert.selected) ?? true)
    );
  }

  function onToggleSkill(event, newSkills) {
    sendTrackingEvent({
      event: "CE_click-edit-profile-onboarding",
      item: "skill",
    });

    if (selectedSkills.includes(event.target.value)) {
      setSelectedSkills((prevSkills) =>
        prevSkills.filter((skill) => skill !== event.target.value)
      );
    } else {
      setSelectedSkills((prev) => [...prev, event.target.value]);
    }
  }

  function onDataDelete(
    resumeSection: keyof UserProfileData,
    deleteIndex: number
  ) {
    setPreviewData((prev) => {
      const newData = {
        ...prev,
        [resumeSection]: (prev?.[resumeSection] as any[])?.filter(
          (_, index) => index !== deleteIndex
        ),
      };
      setEditData(newData);
      return newData;
    });
  }

  function onSaveData() {
    // Save selected data
    let totalSelected = 0;

    const workExperiences = previewData?.workExperiencesStructured?.filter(
      (workExperience) => workExperience.selected
    );
    const educations = previewData?.educationsStructured?.filter(
      (education) => education.selected
    );
    const languages = previewData?.languages?.filter(
      (language) => language.selected
    );
    const certificates = previewData?.certifications?.filter(
      (certificate) => certificate.selected
    );

    let shouldContinue = true;
    setShouldShowError(true);
    if (workExperiences) {
      workExperiences.forEach((workExperience) => {
        if (!shouldContinue) return;
        const validateWorkExperience = isValidWorkExperience(workExperience);
        if (!validateWorkExperience.success) {
          triggerSnackbarFunc({
            snackbarMessage: validateWorkExperience.errorMessage,
            severity: "error",
          });
          shouldContinue = false;
          return;
        }
      });
      if (!shouldContinue) return;
      totalSelected += workExperiences.length;
    }

    if (educations) {
      educations.forEach((education) => {
        if (!shouldContinue) return;
        const validateEducation = isValidEducation(education);
        if (!validateEducation.success) {
          triggerSnackbarFunc({
            snackbarMessage: validateEducation.errorMessage,
            severity: "error",
          });
          shouldContinue = false;
          return;
        }
      });

      if (!shouldContinue) return;
      totalSelected += educations.length;
    }

    if (languages) {
      languages.forEach((language) => {
        if (!shouldContinue) return;
        const validateLanguage = isValidLanguage(language);
        if (!validateLanguage.success) {
          triggerSnackbarFunc({
            snackbarMessage: validateLanguage.errorMessage,
            severity: "error",
          });
          shouldContinue = false;
          return;
        }
      });

      if (!shouldContinue) return;
      totalSelected += languages.length;
    }

    if (certificates) {
      certificates.forEach((certificate) => {
        if (!shouldContinue) return;
        const validateCertificate = isValidCertificate(certificate);
        if (!validateCertificate.success) {
          triggerSnackbarFunc({
            snackbarMessage: validateCertificate.errorMessage,
            severity: "error",
          });
          shouldContinue = false;
          return;
        }
      });

      if (!shouldContinue) return;
      totalSelected += certificates.length;
    }

    sendTrackingEvent({
      event: "CE_click-add-to-profile-onboarding",
      num_items: totalSelected,
    });

    posthog.capture("onboarding_user_profile_complete", {
      origin: "resume_onboarding",
      work_experience: workExperiences.length > 0 ? true : false,
      skills: selectedSkills.length > 0 ? true : false,
      language: languages.length > 0 ? true : false,
      education: educations.length > 0 ? true : false,
      certificate: certificates.length > 0 ? true : false,
    });

    const payload = {
      workExperiences,
      educations,
      languages,
      certificates,
      skills: selectedSkills,
    };

    dispatch(updateOnboardingResume(payload)).then((res: any) => {
      if (res?.type === "UPDATE_ONBOARDING_RESUME_SUCCESS") {
        dispatch(updateProfilePreviewDialog(false)).then(() =>
          router
            .push("/profile#overview")
            .then(() => dispatch(updateResumeFeedbackDialog(true)))
        );
      } else {
        return triggerSnackbarFunc({
          snackbarMessage: "Something went wrong, please try again later.",
          severity: "error",
        });
      }
    });
  }

  return {
    init,
    isEmpty,
    previewData,
    editData,
    progress,
    selectedSkills,
    onSaveData,
    onDataChange,
    onDataDelete,
    onSelectResumeSection,
    onSelectAllResumeSections,
    onSaveEditData,
    undoEditData,
    isAllResumeSectionsSelected,
    hasResumeSectionSelected,
    onToggleSkill,
    isValidData,
    setShouldRefetch,
    reFetch,
    shouldShowError,
  };
}
