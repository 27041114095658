import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { styled as styling } from "@mui/material/styles";
import withTheme from "@mui/styles/withTheme";
import styled from "styled-components";
import Color from "../../../assets/colors";

// Span Component //
export const SpanBold = styled.span`
  && {
    font-weight: bold;
  }
`;

export const TopRowContainer = styling(Grid)({
  display: "flex",
  justifyContent: "flex-end",
});

export const MiddleRowContainer = styling(Grid)({
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "space-between",
  paddingRight: "1rem",
});

export const BlankContainer = styling(Grid)((props) => ({
  color: Color.black,
  [props.theme.breakpoints.down("sm")]: {
    fontSize: "0.85rem",
  },
}));

// Div Components //
export const NotificationPromptContainer = styling(Grid)((props) => ({
  backgroundColor: Color.white,
  borderRadius: "1rem",
  position: "absolute",
  right: "10px",
  padding: "1rem",
  top: "80px",
  maxWidth: "26rem",
  display: props.$close ? "block" : "none",
  zIndex: 50,
  boxShadow: "0px 1px 22px 0px rgba(0, 0, 0, 0.25)",

  [props.theme.breakpoints.down("sm")]: {
    maxWidth: "94%",
    right: "50%",
    transform: "translateX(50%)",
  },
}));

export const TopContent = styled(Grid)`
  && {
    flex-wrap: nowrap;
  }
`;
export const BottomContent = styled.div`
  && {
    text-align: end;
  }
`;

// Icon Components //
export const CloseIconStyled = withTheme(styled(CloseIcon)`
  && {
    //
  }
`);
export const BriefcaseIconStyled = withTheme(styled(BusinessCenterIcon)`
  && {
    margin-right: 1rem;
    width: 2rem;
    height: 2rem;
  }
`);

// Icon Button Component //
export const IconButtonStyled = withTheme(styled(IconButton)`
  && {
    width: 0.75rem;
    height: 0.75rem;
  }
`);

// Button Component //
export const UpdateButton = withTheme(styled(Button)`
  && {
    color: ${Color.hiredlyPurple};
    font-weight: bold;
    text-transform: capitalize;
    font-size: 0.875rem;
  }
`);

// Typography Components //
export const ContentText = withTheme(styled(Typography)`
  && {
    //
  }
`);

export const BoldText = styling(Typography)({
  fontWeight: "bold",
});
