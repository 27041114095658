import { months } from "../../../helpers/constant";
import { isValidUrl } from "../../../helpers/regex";
import {
  EDUCATION_DESCRIPTION_MAX_LENGTH,
  WORK_EXPERIENCE_DESCRIPTION_MAX_LENGTH,
} from "./ProfilePreviewDialog";
import {
  Certification,
  Education,
  Language,
  ValidationResult,
  WorkExperience,
} from "./types";

export function isValidYear(startYear: string, endYear: string) {
  return parseInt(startYear) <= parseInt(endYear);
}

export function isValidMonth(startMonth: string, endMonth: string) {
  const startMonthId = months.find(({ label }) => label === startMonth);
  const endMonthId = months.find(({ label }) => label === endMonth);
  return startMonthId?.id <= endMonthId?.id;
}

// NOTE: Validation logic for all resume data
// Param: resume data
// Return: ValidationResult TYPE
export function isValidWorkExperience(
  workExperience: WorkExperience
): ValidationResult {
  if (
    !workExperience?.currentWork &&
    !isValidYear(workExperience?.startDateYear, workExperience?.endDateYear)
  ) {
    return {
      errorMessage: "Please provide a valid year for work experience",
      success: false,
    };
  }
  if (
    parseInt(workExperience?.startDateYear) ==
      parseInt(workExperience?.endDateYear) &&
    !isValidMonth(workExperience?.startDateMonth, workExperience?.endDateMonth)
  ) {
    return {
      errorMessage: "Please provide a valid month for work experience",
      success: false,
    };
  }
  if (!workExperience.companyName) {
    return { errorMessage: "Company name is required", success: false };
  }
  if (!workExperience.jobTitle) {
    return { errorMessage: "Job title is required", success: false };
  }
  if (
    workExperience.jobDescription?.length >
    WORK_EXPERIENCE_DESCRIPTION_MAX_LENGTH
  ) {
    return {
      errorMessage: `Job description should not be longer than ${WORK_EXPERIENCE_DESCRIPTION_MAX_LENGTH.toString()} characters`,
      success: false,
    };
  }

  return { success: true };
}

export function isValidEducation(education: Education): ValidationResult {
  if (!education.educationLevel) {
    return { errorMessage: "Education level is required", success: false };
  }
  if (!education.educationName) {
    return { errorMessage: "Field of study is required", success: false };
  }
  if (!education.institution) {
    return {
      errorMessage: "Education institution is required",
      success: false,
    };
  }
  if (!education.graduationYear) {
    return { errorMessage: "Graduation year is required", success: false };
  }
  if (education?.description?.length > EDUCATION_DESCRIPTION_MAX_LENGTH) {
    return {
      errorMessage:
        "Education description should not be longer than 1000 characters",
      success: false,
    };
  }
  return { success: true };
}

export function isValidLanguage(language: Language): ValidationResult {
  if (!language.language) {
    return { errorMessage: "Language is required", success: false };
  }
  if (!language.proficiency) {
    return {
      errorMessage: "Language proficiency level is required",
      success: false,
    };
  }
  return { success: true };
}

export function isValidCertificate(
  certificate: Certification
): ValidationResult {
  if (!certificate.name) {
    return { errorMessage: "Certificate name is required", success: false };
  }
  if (!certificate.organisation) {
    return {
      errorMessage: "Issuing organisation for cerfiticate is required",
      success: false,
    };
  }
  if (!certificate.year) {
    return {
      errorMessage: "Certificate completion year is required",
      success: false,
    };
  }
  if (certificate?.url && !isValidUrl(certificate.url)) {
    return {
      errorMessage:
        "Please provide a valid URL and ensure that URL includes “http://” or “https://",
      success: false,
    };
  }
  return { success: true };
}
