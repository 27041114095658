const validateMobileNumber = (mobileNumber) => {
  if (!mobileNumber) return false;

  //const pattern = /^(\+?6?01)[0-46-9]-*[0-9]{7,8}$/;
  const pattern = /^[0-9-+(),.]*$/;

  return pattern.test(mobileNumber);
};

const validateEmail = (email) => {
  const pattern = /^\S+@\S+$/;

  return pattern.test(email);
};

const validateName = (name) => {
  if (!name) return false;
  const pattern = /^[a-zA-Z @./'-]+$/;
  return pattern.test(name);
};

const validateContainsNumbers = (string) => {
  const pattern = /\d/;
  return pattern.test(string);
};

const isValidUrl = (urlString) => {
  var urlPattern = new RegExp(
    "^(https?:\\/\\/)?" + // validate protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // validate fragment locator
  return !!urlPattern.test(urlString);
};

function isURL(url) {
  var strRegex =
    "^((https|http|ftp|rtsp|mms)?://)" +
    "?(([0-9a-z_!~*'().&=+$%-]+: )?[0-9a-z_!~*'().&=+$%-]+@)?" + //ftp的user@
    "(([0-9]{1,3}.){3}[0-9]{1,3}" + // IP形式的URL- 199.194.52.184
    "|" + // 允许IP和DOMAIN（域名）
    "([0-9a-z_!~*'()-]+.)*" + // 域名- www.
    "([0-9a-z][0-9a-z-]{0,61})?[0-9a-z]." + // 二级域名
    "[a-z]{2,6})" + // first level domain- .com or .museum
    "(:[0-9]{1,4})?" + // 端口- :80
    "((/?)|" + // a slash isn't required if there is no file name
    "(/[0-9a-z_!~*'().;?:@&=+$,%#-]+)+/?)$";
  var re = new RegExp(strRegex);
  return re.test(url);
}

function hasHTTPS(str) {
  var tarea = str;
  if (tarea.indexOf("http://") == 0 || tarea.indexOf("https://") == 0) {
    return true;
  }
}

function charIsLetter(char) {
  if (typeof char !== "string") {
    return false;
  }

  return /^[a-zA-Z]+$/.test(char);
}

export {
  validateMobileNumber,
  validateEmail,
  validateName,
  validateContainsNumbers,
  isValidUrl,
  isURL,
  hasHTTPS,
  charIsLetter,
};
