import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openSubSpecialisationDropdown } from "../../../redux/actions/user_action";
import {
  ArrowLeftIconStyled,
  MajorCategoryItemText,
  MajorSpecialisationTitle,
  SubSpecialisationBox,
  SubSpecialisationContent,
  SubSpecialisationItem,
  SubSpecialisationScrollBar,
} from "./styles";

export function SubSpecialisation(props) {
  const classes = SubSpecialisationScrollBar();
  const { specialisationHandler, setAnchorEl } = props;
  const dispatch: any = useDispatch();
  const dropdownState = useSelector(
    (state: any) =>
      state?.user?.openSpecialisationDropdown?.openSpecialisationDropdown
  );
  const subSpecialisationDropdown = useSelector(
    (state: any) =>
      state?.user?.openSubSpecialisationDropdown?.openSubSpecialisationDropdown
  );
  const subSpecialisation = useSelector(
    (state: any) => state?.user?.subSpecialisation?.subSpecialisation
  );

  function handleClickBack() {
    if (dropdownState) {
      dispatch(openSubSpecialisationDropdown(false));
    }
  }

  function handleClickSubCategory(specialisation) {
    specialisationHandler(specialisation);
    dispatch(openSubSpecialisationDropdown(false));
    setAnchorEl(null);
  }

  return (
    <Fragment>
      <SubSpecialisationBox
        $open={subSpecialisationDropdown?.toString()}
        className={classes.scrollBar}
      >
        <SubSpecialisationContent onClick={handleClickBack}>
          <ArrowLeftIconStyled />
          <MajorSpecialisationTitle>
            {subSpecialisation?.name}
          </MajorSpecialisationTitle>
        </SubSpecialisationContent>
        {subSpecialisation?.subSpecialisations?.map((subSpecialisation) => (
          <SubSpecialisationItem
            key={subSpecialisation?.id}
            // @ts-ignore
            onClick={() => handleClickSubCategory(subSpecialisation?.name)}
          >
            <MajorCategoryItemText>
              {subSpecialisation?.name}
            </MajorCategoryItemText>
          </SubSpecialisationItem>
        ))}
      </SubSpecialisationBox>
    </Fragment>
  );
}
