import { Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  clearInfoContent,
  showInfoDialogActivelyAvailable,
} from "../../../redux/actions/dialog_action";
import {
  DialogActionStyled,
  DialogContentBox,
  DialogContentGrid,
  DialogContentStyled,
  DialogStyled,
  DialogText,
  DialogTitleStyled,
  DismissButton,
  RecommendedTextDiv,
  WorkIconStyled,
  WorkOffIconStyled,
} from "./styles";

function ActivelyAvailableDialog(props) {
  const handleDialogOnDismiss = async () => {
    await props.clearInfoContent();
    props.showInfoDialogActivelyAvailable(false);
  };

  return (
    <Fragment>
      <DialogStyled
        open={props.dialogInfoActivelyAvailable}
        onClose={handleDialogOnDismiss}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitleStyled id="alert-dialog-title">
          Let employers discover you!
        </DialogTitleStyled>
        <DialogContentStyled>
          <DialogContentBox
            $margin_bottom
            display="grid"
            gridTemplateColumns="repeat(2, 1fr)"
          >
            <DialogContentBox $padding $background>
              <DialogContentGrid
                container
                $margin_bottom
                display={"flex"}
                justifyContent={"space-between"}
              >
                <DialogContentGrid item display={"flex"}>
                  <DialogContentGrid item $margin_right>
                    <WorkIconStyled />
                  </DialogContentGrid>
                  <DialogText $bold>I'm open for jobs</DialogText>
                </DialogContentGrid>
                <DialogContentGrid item>
                  <RecommendedTextDiv>
                    <DialogText $bold $caption>
                      Recommended
                    </DialogText>
                  </RecommendedTextDiv>
                </DialogContentGrid>
              </DialogContentGrid>
              <DialogText variant="body1">
                You will be listed in an exclusive list for employers to
                discover and contact you with job opportunities.
              </DialogText>
            </DialogContentBox>

            <DialogContentBox $padding $background>
              <DialogContentGrid container $margin_bottom>
                <DialogContentGrid item $margin_right>
                  <WorkOffIconStyled />
                </DialogContentGrid>
                <DialogContentGrid item>
                  <DialogText $bold>I'm not open for jobs</DialogText>
                </DialogContentGrid>
              </DialogContentGrid>
              <DialogText variant="body1">
                You will not be listed in an exclusive list for employers to
                discover but employers can view your applications.
              </DialogText>
            </DialogContentBox>
          </DialogContentBox>
          <DialogText $text_align>
            We hope that this initiative will help you get one step closer to
            finding a job you love.
          </DialogText>
        </DialogContentStyled>
        <DialogActionStyled>
          <DismissButton onClick={handleDialogOnDismiss}>Got It!</DismissButton>
        </DialogActionStyled>
      </DialogStyled>
    </Fragment>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    clearInfoContent: bindActionCreators(clearInfoContent, dispatch),
    showInfoDialogActivelyAvailable: bindActionCreators(
      showInfoDialogActivelyAvailable,
      dispatch
    ),
  };
};

const mapStateToProps = (state) => ({
  dialogInfoActivelyAvailable: state.dialog.dialogInfoActivelyAvailable,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivelyAvailableDialog);
