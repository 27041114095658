import { useRouter } from "next/router";
import { Fragment, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import Spinner from "../../../components/shared/SharedSpinner/SharedSpinner";
import { getCompanySuggestion } from "../../../redux/actions/company_action";
import {
  clearSearchSuggestions,
  fetchSearchSuggestions,
  getFeaturedJobs,
  getJobs,
  updateJobListFilter,
} from "../../../redux/actions/job_action";
import { updateShowMobileFilter } from "../../../redux/actions/navbar_action";
import { store } from "../../../redux/stores/store";
import {
  JobTitleStyled,
  SpinnerContainer,
  SuggestionContainer,
  TitleContainer,
  TypeTitleContainer,
  TypeTitleStyled,
} from "./styles";

function SharedMobileSuggestion(props) {
  const { setSearch } = props;
  const router = useRouter();

  const dispatch = useDispatch();

  useEffect(() => {
    loadFeaturedDatas();

    if (props.jobListFilter?.keyword?.length > 0) {
      let params = {
        keyword: props.jobListFilter.keyword,
      };
      props.fetchSearchSuggestions(params);
    }

    return () => {
      props.clearSearchSuggestions();
    };
  }, []);

  useEffect(() => {
    let params = {
      keyword: props.jobListFilter.keyword,
    };
    props.fetchSearchSuggestions(params);
  }, [props.jobListFilter.keyword]);

  const loadFeaturedDatas = () => {
    if (props.page === "company-list-page") {
      if (store.getState().companies.discoverCompanies.length === 0) {
        const params = {
          canCancel: false,
        };
        props.getCompanySuggestion(params);
      }
    } else {
      if (store.getState().jobs.featuredJobs.length === 0) {
        props.getFeaturedJobs();
      }
    }
  };

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop - 50 <= e.target.clientHeight;
    if (bottom) {
      if (props.page === "company-list-page") {
        props.triggerGetCompanySuggestions(false);
      }
    }
  };

  const onSuggestionClicked = (suggestion, type) => (e) => {
    let keyword;
    if (type === "featuredJob") {
      keyword = suggestion.title;
    } else {
      keyword = suggestion.name;
    }

    window.scrollTo(0, 0);

    if (props.page === "company-list-page") {
      router.push(`/companies/${suggestion.slug}`);
    } else {
      if (props.page === "job-list-page") {
        props.onTriggerDiscoverJob(keyword);
      } else {
        router.push("/jobs");
      }
    }
    props.updateShowMobileFilter(false);
  };

  const getSuggestions = (page, jobSuggestions, companySuggestions) => {
    if (page === "company-list-page") {
      return companySuggestions;
    } else {
      return jobSuggestions;
    }
  };

  const getFeatureds = (page, featuredJobs, featuredCompanies) => {
    if (page === "company-list-page") {
      return featuredCompanies;
    } else {
      return featuredJobs;
    }
  };

  const isFetchingFeatured = (
    page,
    fetchingFeaturedJobs,
    fetchingFeaturedCompanies
  ) => {
    if (page === "company-list-page") {
      return fetchingFeaturedCompanies;
    } else {
      return fetchingFeaturedJobs;
    }
  };

  const isFetching = (
    page,
    fetchingJobSuggestion,
    fetchingCompanySuggestion
  ) => {
    if (page === "company-list-page") {
      return fetchingCompanySuggestion;
    } else {
      return fetchingJobSuggestion;
    }
  };

  const getKeyword = (page, jobLandingFilter, jobListFilter, companyFilter) => {
    if (page === "company-list-page") {
      if (companyFilter?.keyword) {
        return companyFilter.keyword;
      }
    } else if (page === "job-list-page" || page === "single-job-page") {
      if (jobListFilter?.keyword) {
        return jobListFilter.keyword;
      }
    } else if (page === "user-landing-page") {
      if (jobLandingFilter?.keyword) {
        return jobLandingFilter.keyword;
      }
    }

    return "";
  };

  const boldMatchingText = (suggestion) => {
    const n = suggestion.toLowerCase();
    const q = getKeyword(
      props.page,
      props.jobListFilterLanding,
      props.jobListFilter,
      props.companyFilter
    )
      .toString()
      .toLowerCase();
    const x = n.indexOf(q);
    // if suggestion has no matching characters, return suggestion
    if (!q || x === -1) {
      return (
        <span
          dangerouslySetInnerHTML={{
            __html: suggestion,
          }}
        />
      );
    }
    // else bold characters that match search input
    const l = q.length;
    const boldedSearch =
      suggestion.substr(0, x) +
      "<b>" +
      suggestion.substr(x, l) +
      "</b>" +
      suggestion.substr(x + l);
    return (
      <span
        dangerouslySetInnerHTML={{
          __html: boldedSearch,
        }}
      />
    );
  };

  return (
    <Fragment>
      <SuggestionContainer onScroll={handleScroll}>
        {getKeyword(
          props.page,
          props.jobListFilterLanding,
          props.jobListFilter,
          props.companyFilter
        ) != "" ? (
          <Fragment>
            <TypeTitleContainer>
              <TypeTitleStyled>
                {props.page === "company-list-page"
                  ? "Suggested Companies"
                  : "Suggested Jobs"}
              </TypeTitleStyled>
            </TypeTitleContainer>
            {getSuggestions(
              props.page,
              props.searchData,
              props.companySuggestions
            ).map((suggestion, index) => (
              <TitleContainer
                key={index}
                onClick={onSuggestionClicked(suggestion)}
              >
                <JobTitleStyled>
                  {boldMatchingText(
                    suggestion.title ? suggestion.title : suggestion.name
                  )}
                </JobTitleStyled>
              </TitleContainer>
            ))}
            {isFetching(
              props.page,
              props.fetchingJobSuggestion,
              props.fetchingCompanySuggestion
            ) && (
              <SpinnerContainer key={"spinner"}>
                <Spinner width={36} height={36} />
              </SpinnerContainer>
            )}
          </Fragment>
        ) : (
          <Fragment>
            <TypeTitleContainer>
              <TypeTitleStyled>
                {props.page === "company-list-page"
                  ? "Featured Companies"
                  : "Featured Jobs"}
              </TypeTitleStyled>
            </TypeTitleContainer>
            {getFeatureds(
              props.page,
              props.featuredJobs,
              props.discoverCompanies
            ).map((featured, index) => (
              <TitleContainer
                key={index}
                onClick={onSuggestionClicked(featured, "featuredJob")}
                className={
                  props.page === "company-list-page"
                    ? "searchbar-suggested-featured-company"
                    : "searchbar-suggested-featured-job"
                }
              >
                <JobTitleStyled>
                  {featured.title || featured.name}
                </JobTitleStyled>
              </TitleContainer>
            ))}
            {isFetchingFeatured(
              props.page,
              props.fetchingFeaturedJobs,
              props.fetchingDiscoverCompanies
            ) && (
              <SpinnerContainer key={"spinner"}>
                <Spinner width={36} height={36} />
              </SpinnerContainer>
            )}
          </Fragment>
        )}
      </SuggestionContainer>
    </Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    jobListFilter: state.jobs.jobListFilter,
    jobListFilterLanding: state.jobs.jobListFilterLanding,
    fetchingJobSuggestion: state.jobs.fetchingJobSuggestion,
    jobSuggestions: state.jobs.jobSuggestions,
    fetchingCompanySuggestion: state.companies.fetchingCompanySuggestion,
    companySuggestions: state.companies.companySuggestions,
    fetchingFeaturedJobs: state.jobs.fetchingFeaturedJobs,
    featuredJobs: state.jobs.featuredJobs,
    companyFilter: state.companies.companyFilter,
    discoverCompanies: state.companies.discoverCompanies,
    fetchingDiscoverCompanies: state.companies.fetchingDiscoverCompanies,
    searchData: state.jobs.searchData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getJobs: bindActionCreators(getJobs, dispatch),
    getFeaturedJobs: bindActionCreators(getFeaturedJobs, dispatch),
    updateShowMobileFilter: bindActionCreators(
      updateShowMobileFilter,
      dispatch
    ),
    getCompanySuggestion: bindActionCreators(getCompanySuggestion, dispatch),
    fetchSearchSuggestions: bindActionCreators(
      fetchSearchSuggestions,
      dispatch
    ),
    clearSearchSuggestions: bindActionCreators(
      clearSearchSuggestions,
      dispatch
    ),
    updateJobListFilter: bindActionCreators(updateJobListFilter, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SharedMobileSuggestion);
