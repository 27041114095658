import { FontSize } from "../../../assets/font";
import styled from "styled-components";
import Typography from "@mui/material/Typography";

export const SuggestionContainer = styled.div`
  && {
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const SpinnerContainer = styled.div`
  && {
    margin-top: 15px;
    display: flex;
    justify-content: center;
    width: 100%;
  }
`;

export const TitleContainer = styled.div`
  && {
    padding: 10px 0;
    margin-top: 10px;
    cursor: pointer;
    width: 92%;
    white-space: nowrap;
  }
`;

export const TypeTitleContainer = styled.div`
  && {
    margin-top: 10px;
    cursor: pointer;
    width: 92%;
  }
`;

// Typography Components //
export const TypeTitleStyled = styled(Typography)`
  && {
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
  }
`;

export const JobTitleStyled = styled(Typography)`
  && {
    font-size: ${FontSize.desktopBody};
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
