import { useMediaQuery } from "@mui/material";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { DialogContainer, ShieldWarningIcon, TextContainer } from "./styles";

function SharedMaintenanceDialog(props) {
  const router = useRouter();
  const isDesktop = useMediaQuery("(min-width: 1024px)");
  const isTablet = useMediaQuery("(min-width: 640px)");

  const [triggerClose, setTriggerClose] = useState(false);

  let warningCount = sessionStorage.getItem("maintenance-warning");

  useEffect(() => {
    warningCount = sessionStorage.getItem("maintenance-warning");
  }, [triggerClose]);

  function handleClose() {
    setTriggerClose(true);
    sessionStorage.setItem("maintenance-warning", false);
  }

  const isHomepage = router.pathname === "/";

  return (
    <DialogContainer isHomepage={isHomepage} show={true}>
      <ShieldWarningIcon />
      <TextContainer>
        Beware of fraud! Hiredly never asks job seekers for payments. Report
        suspicious activity to <b>hello@hiredly.com</b>
      </TextContainer>
      {/* {isTablet ? (
        <CloseButton onClick={handleClose}>
          <CloseIcon />
        </CloseButton>
      ) : null} */}
    </DialogContainer>
  );
}

export default SharedMaintenanceDialog;
