import useTriggerSnackbar from "../../../hooks/useTriggerSnackbar";
import {
  CardCaption,
  CardHeader,
  CardSubtitle,
  CardTitle,
  LinkIconButtonStyled,
  LinkIconStyled,
  TooltipStyled,
} from "./styles";
import { Certification, Education, Language, WorkExperience } from "./types";

export function WorkExperienceCardHeader(props: {
  workExperience: WorkExperience;
}) {
  const { workExperience } = props;

  return (
    <CardHeader>
      <CardTitle>{workExperience?.jobTitle ?? "-"}</CardTitle>
      <CardSubtitle>{workExperience?.companyName ?? "-"}</CardSubtitle>
      <CardCaption>
        {workExperience?.startDateMonth ?? "-"}{" "}
        {workExperience?.startDateYear ?? "-"} -{" "}
        {workExperience?.currentWork
          ? "Present"
          : `${workExperience?.endDateMonth ?? "-"} ${
              workExperience?.endDateYear ?? "-"
            }`}
      </CardCaption>
    </CardHeader>
  );
}

export function EducationCardHeader(props: { education: Education }) {
  const { education } = props;
  return (
    <CardHeader>
      <CardTitle>{education?.institution ?? "-"}</CardTitle>
      <CardSubtitle>{education?.educationName ?? "-"}</CardSubtitle>
      <CardCaption>{education?.graduationYear ?? "-"}</CardCaption>
    </CardHeader>
  );
}

export function LanguageCardHeader(props: { language: Language }) {
  const { language } = props;
  return (
    <CardHeader>
      <CardTitle>{language?.language ?? "-"}</CardTitle>
      <CardSubtitle>
        {language?.proficiency ? `${language?.proficiency} Proficiency` : "-"}
      </CardSubtitle>
    </CardHeader>
  );
}

export function CertificateHeader(props: { certificate: Certification }) {
  const { certificate } = props;
  const [triggerSnackbarFunc] = useTriggerSnackbar();

  function onCopy(e: React.MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    navigator.clipboard.writeText(certificate?.url);

    triggerSnackbarFunc({
      snackbarMessage: "Copied to clipboard.",
      severity: "success",
    });
  }

  return (
    <CardHeader>
      <CardTitle>
        {certificate?.name ?? "-"}
        {certificate?.url && (
          <TooltipStyled title={certificate?.url} placement="top">
            <LinkIconButtonStyled onClick={onCopy}>
              <LinkIconStyled />
            </LinkIconButtonStyled>
          </TooltipStyled>
        )}
      </CardTitle>
      <CardSubtitle>{certificate?.organisation ?? "-"}</CardSubtitle>
      <CardCaption>
        Issued in
        {certificate?.year ?? "-"}
      </CardCaption>
    </CardHeader>
  );
}
