import { useDispatch, useSelector } from "react-redux";
import { hideSnackBar } from "../../../redux/actions/snackbar_action";
import {
  CloseIconStyled,
  LinkStyled,
  SeverityContainer,
  SnackBarContainer,
  SnackbarAccent,
  SnackbarCheckCircleIconStyled,
  SnackbarErrorCircleIconStyled,
  SnackbarIconButtonStyled,
  SnackbarMessageText,
  SnackbarSeverityText,
  SnackbarStyled,
} from "./styles";

function SharedSnackbar() {
  const dispatch = useDispatch();

  const snackbarState = useSelector((state) => state?.snackbar) ?? {};

  const {
    showSnackBar,
    snackBarMessage: snackbarMessage,
    severity,
    key: snackbarKey,
    showActionButton,
  } = snackbarState;

  const handleSnackBarClosed = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    dispatch(hideSnackBar());
  };

  return (
    <SnackbarStyled
      key={snackbarKey}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={showSnackBar}
      autoHideDuration={6000}
      onClose={handleSnackBarClosed}
    >
      <SnackBarContainer
        $fullWidth={showActionButton === true ? true : false}
        $severity={severity}
      >
        <SnackbarAccent $severity={severity}></SnackbarAccent>
        <SeverityContainer>
          <SeverityContainer>
            {severity === "success" || severity === "ashley" ? (
              <SnackbarCheckCircleIconStyled $severity={severity} />
            ) : (
              <SnackbarErrorCircleIconStyled $severity={severity} />
            )}

            <SnackbarSeverityText variant="subtitle1">
              {severity === "success"
                ? "Success"
                : severity === "warning"
                ? "Uh-oh"
                : severity === "ashley"
                ? "Ashley sleeps"
                : "Error"}
            </SnackbarSeverityText>
          </SeverityContainer>
          <SnackbarIconButtonStyled onClick={handleSnackBarClosed}>
            <CloseIconStyled />
          </SnackbarIconButtonStyled>
        </SeverityContainer>
        <SnackbarMessageText variant="subtitle2">
          {snackbarMessage}{" "}
          {showActionButton === true && (
            <LinkStyled href="/profile#manage-job-alerts">
              Manage Job Alert
            </LinkStyled>
          )}
        </SnackbarMessageText>
      </SnackBarContainer>
    </SnackbarStyled>
  );
}

export default SharedSnackbar;
