import { FontSize } from "../../../assets/font";
import Color from "../../../assets/colors";
import styled from "styled-components";
import withTheme from "@mui/styles/withTheme";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";

// Button Component //
export const ResetPasswordButton = withTheme(styled(Button)`
  && {
    font-weight: 700;
    height: 42px;
    text-transform: capitalize;
    font-size: 1rem;
    width: 100%;
  }
`);

// Grid Components //
export const ForgotPasswordContainer = styled(Grid)`
  && {
    width: 100%;
  }
`;

export const ForgotPasswordFormContainer = styled(Grid)`
  && {
    margin: 0 0 30px 0;
  }
`;

export const ForgotPasswordButtonContainer = styled(Grid)`
  && {
    display: flex;
    justify-content: center;
  }
`;

// Typography Components //
export const LabelStyled = withTheme(styled(Typography)`
  && {
    margin-left: ${(props) => (props.$margin ? "0" : "3%")};
    font-weight: bold;
    margin-top: 8px;
    margin-bottom: 8px;
    font-size: ${FontSize.desktopBody};
    color: ${(props) => props.$divider_text && `${Color.secondaryGrey}`};
    text-transform: capitalize;

    ${(props) => props.theme.breakpoints.down("tabletS")} {
      margin-bottom: 8px;
    }
  }
`);

// TextField Components //
export const TextInput = styled(TextField)`
  && {
    display: flex;
  }
`;

// DialogContent Components //
export const DialogContentStyled = withTheme(styled(DialogContent)`
  && {
    padding: 20px;
    width: 100%;

    ${(props) => props.theme.breakpoints.down("mobileL")} {
      max-width: 100%;
      padding: 10px 20px 20px;
    }
  }
`);
